import React, {useState} from 'react';
import areas_arrow from 'assets/images/areas_arrow.svg';
export const Collapse = (props) => {
  const {title, content, id, isOpen} = props;
  return (
    <div className='collapse-container'>
      <hr />
      <div
        className={'button' + (isOpen ? '' : ' collapsed')}
        role='button'
        data-toggle='collapse'
        href={`#${id}`}
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-controls={id}>
        {title}
        <img className='arrow' src={areas_arrow} alt='areas_arrow' />
      </div>
      <hr />
      <div className={'collapse' + (isOpen ? ' in' : '')} id={id}>
        {props.children || <p className='paragraph'>{content}</p>}
      </div>
    </div>
  );
};
