import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import heart_icon from 'assets/images/heart_icon.png';
import stethoscope_icon from 'assets/images/stethoscope_icon.png';
import scanner from 'assets/images/scanner.png';
import fai_illustration from 'assets/images/fai_illustration.jpg';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>

        <div className='home-background'></div>
        <div className='home-container'>
          <div className='home-section home-hero container'>
            <div className='home-hero__title'>
              <h2 className='hero-title'>
                Predicting your Heart Attack risk
              </h2>
              <div className='hero-sub-title'>
                by detecting invisible coronary inflammation
              </div>
            </div>
          </div>
          <div className='home-section home-content-1 container'>
            <div className='row'>
              <div className='col-sm-12 col-md-7'>
                <h2 className='section-title'>
                  Inflammation causes <br /> Heart Attacks
                </h2>
                <p className='section-sub-title'>
                  Coronary Artery Disease (CAD) is the world&apos;s biggest
                  killer, but often goes unnoticed until it is too late. For
                  decades, cardiologists have known that inflammation is the key
                  process that drives the development of CAD and plaque rupture;
                  but it is not detectable with current routine diagnostic tests.
                </p>
              </div>
            <div className='col-md-1 space'></div>
            <div className='col-sm-12 col-md-4 no-padding'>
              <div className='col-sm-12 col-md-12'>
                <div className='row'>
                  <div className='home-notice'>
                    <img src={heart_icon} alt='heart' />
                    <a href='/patients/what-is-cardiovascular-disease'>
                      Learn more about your heart and coronary artery disease
                    </a>
                  </div>
                </div>
                <div className='row'>
                  <div className='home-notice'>
                    <img src={stethoscope_icon} alt='stethoscope' />
                    <a href='/professionals/clinical-evidence?step=1'>
                      Read how treating inflammation reduces events
                    </a>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <hr className='divider-sm'></hr>
          </div>
          <div className='home-section home-content-2 container'>
            <div className='col-sm-12 col-md-6'>
              <img className='scanner' src={scanner} alt='scanner' />
            </div>
            <div className='col-sm-12 col-md-6'>
              <h2 className='section-title'>
                Caristo&apos;s innovative solution measures inflammation
              </h2>
              <div className='col-sm-12 col-md-5'>
                <img className='scanner-sm' src={scanner} alt='scanner' />
              </div>
              <p className='section-sub-title'>
                Caristo has developed state-of-the-art image analysis
                technology, that can detect and quantify the invisible
                signatures of inflammation in routine CT heart scans. This
                non-invasive technique is widely available and cost effective.
              </p>
              <div className='col-sm-12 col-md-12 no-padding'>
                <div className='row'>
                  <div className='col-sm-12 col-md-8 home-notice'>
                    <img src={heart_icon} alt='heart' />
                    <a href='/patients/what-is-ccta'>
                      Learn more about Cardiac CT testing
                    </a>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12 col-md-8 home-notice'>
                    <img src={stethoscope_icon} alt='stethoscope' />
                    <a href='/professionals/clinical-evidence?step=2'>
                      Understand the science supporting our innovative technology
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <hr className='divider-sm'></hr>
          </div>
          <div className='home-background-2'></div>
          <div className='home-section home-content-3 container'>
            <div className='col-sm-12 col-md-6'>
              <h2 className='section-title'>
                Personalized Cardiovascular Risk prediction with CaRi-Heart®
                Risk
              </h2>
              <p className='section-sub-title'>
                Combining our proprietary measurements of coronary artery
                inflammation with other clinical risk factors, we can accurately
                predict an individual’s risk of heart attack - years in advance.
              </p>
              <p className='section-sub-title'>
                This personalized risk scoring is highly accurate, and has been
                validated in thousands of patients.
              </p>
              <div className='col-sm-12 col-md-6 fai-scan-sm'>
                <img className='fai-img' src={fai_illustration} alt='fai_illustration' />
              </div>
              <div className='col-sm-12 col-md-12 no-padding'>
                <div className='row'>
                  <div className='col-sm-12 col-md-8 home-notice'>
                    <img src={heart_icon} alt='heart' />
                    <a href="/patients/what-is-cardiovascular-disease?expanded=section3#collapse">
                      Learn about your cardiac risk and understand how to manage it
                    </a>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12 col-md-8 home-notice'>
                    <img src={stethoscope_icon} alt='stethoscope' />
                    <a href='/professionals/clinical-evidence?step=3'>
                      Read about outcome studies and CaRi-Heart® Risk prognostic
                      value
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 fai-scan'>
              <img className='fai-img' src={fai_illustration} alt='fai_illustration' />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
