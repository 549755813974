import React from 'react';
import slices_orange_arrow from 'assets/images/slices_orange_arrow.svg';

export const Step = (props) => {
  const {list} = props;
  return (
    <div className='row no-margin step-container'>
      {list.map((step, index) => (
        <span className="step-sm-row" key={index}>
        <div className='step-text col-xs-2 col-sm-2 col-md-2'>{step}</div>
        {index < list.length - 1 && (
          <div className='step-arrow-col col-xs-1 col-sm-1 col-md-1'>
            <img className='step-arrow' src={slices_orange_arrow} alt='slices_orange_arrow' />
          </div>
          )}
      </span>
      ))}
    </div>
  );
};
