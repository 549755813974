import BreadCrumb from 'components/Breadcrumb';
import RightLink from 'components/RightLink';
import {rightlinks} from 'contents';
import React from 'react';

import Video_caption_1 from 'assets/images/professional/education/Video_caption_1.png';
import Video_caption_2 from 'assets/images/professional/education/Video_caption_2.png';
import Video_caption_3 from 'assets/images/professional/education/Video_caption_3.png';
import Video_caption_4 from 'assets/images/professional/education/Video_caption_4.png';
import Video_caption_5 from 'assets/images/professional/education/Video_caption_5.png';
import Video_caption_6 from 'assets/images/professional/education/Video_caption_6.png';

const videos = [
  {
    image: Video_caption_6,
    title:
      'Novel insights in inflammatory makers, AI, diagnostic tools and prediction of CV risk',
      description: `This series consists of three educational videos. In the first lecture, Peter Libby discusses the role of inflammation in atherosclerosis. In the second lecture, Charalambos Antoniades talks about detecting residual cardiovascular risk using coronary CT angiography. The third and last video is a panel discussion with Peter Libby, Charalambos Antoniades and John Deanfield.`,
    date: 'October 2021',
    link:
      'https://pace-cme.org/2021/10/05/novel-insights-in-inflammatory-makers-ai-diagnostic-tools-and-prediction-of-cv-risk/'
  },
  {
    image: Video_caption_4,
    title:
      'Quantification of coronary inflammation using coronary CT angiography to improve patient risk prediction and stratification',
      description: `Join Profs. Charalambos Antoniades, Steffen Petersen and Dr. Gianluca
      Pontone in this European Association of Cardiovascular Imaging webinar`,
    date: 'September 2021',
    link:
      'https://www.escardio.org/Education/E-Learning/Webinars/Quantification-of-coronary-inflammation-using-coronary-CT-angiography-to-improve-patient-risk-prediction-and-stratification'
  },
  {
    image: Video_caption_5,
    title:
      'SCCT Symposium - Beyond plaque assessment: Fat Attenuation Index to personalize CV risk prediction',
    date: 'July 2021',
    link:
      'https://www.youtube.com/watch?v=1Ihks0z1ecg&t=10s'
  },
  {
    image: Video_caption_3,
    title:
      'Anti-inflammation heralds the future of CV medicine',
      description: `From CANTOS to COLCOT to COPS to LoDoCo2: Dr. Ridker presents the lessons
      from these trials with anti-inflammatory treatment, in particular those with
      colchicine, in patients with residual inflammatory risk.`,
    date: 'February 2021',
    link:
      'https://pace-cme.org/2021/02/08/anti-inflammation-heralds-the-future-of-cv-medicine/'
  },
  {
    image: Video_caption_1,
    title:
      'Anti-inflammatory therapy in the prevention of cardiovascular disease',
      description: `Prof. Libby discusses IL-1β as a target for atherosclerosis therapy, a strategy that was evaluated in the CANTOS trial, which tested the monoclonal antibody canakinumab`,
    date: 'November 2017',
    link:
      'https://pace-cme.org/2018/03/27/anti-inflammatory-therapy-in-the-prevention-of-cardiovascular-disease/'
  },
  {
    image: Video_caption_2,
    title:
      'The journey from vascular biology to the clinic: reducing inflammation lowers CV risk',
      description: `Inflammation expert opinions aimed to provide guidance and perspectives on the implications of the CANTOS trial and other trials addressing inflammation in CVD. These expert views may help translate scientific findings to future clinical practice, by improving personalized CV risk management.`,
    date: 'August 2017',
    link:
      'https://pace-cme.org/2017/09/19/the-journey-from-vascular-biology-to-the-clinic-reducing-inflammation-lowers-cv-risk/'
  }
];
export default function Educations() {
  return (
    <>
      <div className='professional-background-1' />
      <div className='education-background-2' />
      <div className='professionals-container container education'>
        <BreadCrumb subRouter='Professionals' subHeading='Education' />
        <div className='row header'>
          <div className='col-md-8'>
            <p className='page-title'>Webinars/Seminars</p>

            <div className='videos-section'>
              {videos.map((item, index) => (
                <div className='video-item' key={index}>
                  <div className='date'>{item.date}</div>
                  <div className='title'>{item.title}</div>
                  <a href={item.link} target='_blank' className='thumbnail'>
                    <img src={item.image} />
                  </a>
                  <div className='description'>{item.description}</div>
                </div>
              ))}
            </div>
          </div>
          <div className='col-md-4'>
            <RightLink content={rightlinks[1]} />
          </div>
        </div>
      </div>
    </>
  );
}
