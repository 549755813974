import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import {latestNews} from 'contents/news';

const PostList = function ({posts}) {
  let { path, url } = useRouteMatch();
  return (
    <div className='post-list'>
      {posts.map((post, index) => (
        <div className='post-item' key={index}>
          <div className='post-item__date'>{post.date}</div>
          {post.slug || post.link ? (
            <a
              href={post.link ? post.link : url + '/' + post.slug}
              className='link post-item__title'
              target={post.link ? '_blank' : '_self'}>
              {post.title}
            </a>
          ) : (
            <div className='post-item__title'>{post.title}</div>
          )}
          <div className='post-item__description'>{post.description}</div>
        </div>
      ))}
    </div>
  );
};

export default function NewsList() {
  return (
    <div>
      <p className='page-title'>Newsroom</p>
      <PostList posts={latestNews}></PostList>
    </div>
  )
}
