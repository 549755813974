import React from 'react';
import { Link } from 'react-router-dom';
import 'assets/styles/pages/_productsandbrochures.scss';
import BreadCrumb from 'components/Breadcrumb';
import HoverDisplay from 'components/HoverDisplay';

import PABflyer from 'assets/pdfs/Caristo_Flyer_1413.pdf'
import PABinterpretationguide from 'assets/pdfs/CaRi-Heart interpretation guide_1384.pdf'
import PABoverviewforpayers from 'assets/pdfs/CaRi-HEART Overview_Insurers_Oct 2021_rev7 UK.pdf'



class ProductsAndBrochures extends React.Component {
    render() {
      return (
        <div className='patients-container ccta'>
          <div className='container'>
            <BreadCrumb subRouter='Resources' subHeading='Product and Brochures' />
            <div className='row'>
              <div className='col-xs-12 col-md-12'>
                <p className='page-title'>Product and Brochures</p>
                <p className='paragraph'>
                  Our professional resources are there to help you learn more about CaRi-Heart® for clinical use. Keep visiting this space for the latest brochures, guidelines, and FAQs for CaRi-Heart® analysis.                
                </p>
                <p className='paragraph'>
                  The following documents are free to download. For any further questions, please reach out to us on info@caristo.com
                </p>
              </div>
            </div>
            <div className='row'>
            <div className='col-md-6 col-lg-4 pr-resources-tab'>
              <a className='images diagram-link' href={PABflyer} target='_blank'>
                <div className='pab-pill'>
                  <p className='title'>CaRi-Heart® Information Leaflet</p>
                  Learn about the science behind CaRi-Heart® and its application in clinical workflows
                </div>
              </a>
            </div>
            <div className='col-md-6 col-lg-4 pr-resources-tab'>
              <a className='images diagram-link' href={PABinterpretationguide} target='_blank'>
                <div className='pab-pill'>
                  <p className='title'>CaRi-Heart® Interpretation Guide</p>
                  How to read the CaRi-Heart® report. What does the score indicate?
                </div>
              </a>
            </div>
            <div className='col-md-6 col-lg-4 pr-resources-tab'>
              <a className='images diagram-link' href={PABoverviewforpayers} target='_blank'>
                <div className='pab-pill'>
                  <p className='title'>Overview for Payers</p>
                  A brief on CaRi-Heart® analysis to be shared with payers/insurers
                </div>
              </a>
            </div>
          </div>
          </div>
        </div>
      );
    }
  }
  
  export default ProductsAndBrochures;
  