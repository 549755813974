import BreadCrumb from 'components/Breadcrumb';
import RightLink from 'components/RightLink';
import {StepCarousel} from 'components/StepCarousel';
import {SmStepCarousel} from 'components/SmStepCarousel';
import {rightlinks} from 'contents';
import React, {useState, useEffect, useRef} from 'react';
import {Tabs, Tab } from 'react-bootstrap';
import {
  Switch,
  useRouteMatch,
  useHistory,
  Route,
  useParams,
  useLocation
} from 'react-router-dom';
import magnifying_glass from 'assets/images/magnifying_glass.svg';
import step1 from 'assets/images/professional/step_1/1.svg';
import smstep1 from 'assets/images/professional/step_1/sm_1.svg';
import step2 from 'assets/images/professional/step_2/2.svg';
import smstep2 from 'assets/images/professional/step_2/sm_2.svg';
import step3 from 'assets/images/professional/step_3/3.svg';
import smstep3 from 'assets/images/professional/step_3/sm_3.svg';
import step4 from 'assets/images/professional/step_4/4.svg';
import smstep4 from 'assets/images/professional/step_4/sm_4.svg';
import steps_arrow from 'assets/images/steps_arrow.svg';
import step1_graph1 from 'assets/images/professional/step_1/1_graph1.svg';
import step1_graph2 from 'assets/images/professional/step_1/1_graph2.svg';
import step1_graph3 from 'assets/images/professional/step_1/1_graph3.svg';
import step1_graph4 from 'assets/images/professional/step_1/1_graph4.svg';
import Clinical_Evidence from 'assets/images/professional/step_2/Clinical_Evidence.mp4';
import step2_image1 from 'assets/images/professional/step_2/2_image1.jpg';
import step2_graph1 from 'assets/images/professional/step_2/2_graph1.svg';
import step2_image3 from 'assets/images/professional/step_2/2_image3.jpg';
import step2_image2 from 'assets/images/professional/step_2/2_image2.jpg';
import step2_graph2 from 'assets/images/professional/step_2/2_graph2.svg';
import step2_image4 from 'assets/images/professional/step_2/2_image4.jpg';
import step2_FAI from 'assets/images/professional/step_2/2_FAI.svg';
import step3_graph1 from 'assets/images/professional/step_3/3_graph1.svg';
import step3_graph2 from 'assets/images/professional/step_3/3_graph2.svg';
import step3_graph3 from 'assets/images/professional/step_3/3_graph3.svg';
import step4_diagram_1 from 'assets/images/professional/step_4/4_diagram_1.svg';
import step4_diagram_2 from 'assets/images/professional/step_4/4_diagram_2.svg';
import step4_diagram_3 from 'assets/images/professional/step_4/4_diagram_3.svg';
import step4_diagram from 'assets/images/professional/step_4/4_diagram.svg';
import step4_scan_1 from 'assets/images/professional/step_4/scan-1.jpg';
import step4_scan_2 from 'assets/images/professional/step_4/scan-2.jpg';
import step4_scan_arrow from 'assets/images/professional/step_4/4_arrow.svg';
import step4_scan_graph from 'assets/images/professional/step_4/4_graph.svg';
import step4_scan_graph_2 from 'assets/images/professional/step_4/4_graph_3.svg';

function UpArrow({currentStepHovered}) {
  return (
    <div style={{position: 'absolute', left: '50%', bottom: '-70px'}}>
      <img className='steps-arrow' src={steps_arrow} />
    </div>
  );
}
export default function ClinicalEvidence(props) {
  const [currentStepHovered, setCurrentStepHovered] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);

  const history = useHistory();

  let {path, url} = useRouteMatch();
  let {search} = useLocation();

  let {step} = useParams();

  const stepContentRef = useRef(null)

  const stepList = [
    {
      img: step1,
      children: (
        <div className='steps-text steps-text-1'>
          <h5>Treating Inflammation reduces cardiovascular adverse events</h5>
          {currentStepHovered === 1 && <UpArrow />}
        </div>
      )
    },
    {
      img: step2,
      children: (
        <div className='steps-text steps-text-2'>
          <h5>FAI is a novel imaging marker of coronary inflammation</h5>
          {currentStepHovered === 2 && <UpArrow />}
        </div>
      )
    },
    {
      img: step3,
      children: (
        <div className='steps-text steps-text-3'>
          <h5>FAI has prognostic value for cardiac mortality</h5>
          {currentStepHovered === 3 && <UpArrow />}
        </div>
      )
    },
    {
      img: step4,
      children: (
        <div className='steps-text steps-text-4'>
          <h5>
            Using FAI in the CaRi-Heart&reg; Risk provides a personalized
            cardiovascular risk prediction
          </h5>
          {currentStepHovered === 4 && <UpArrow />}
        </div>
      )
    }
  ];

  const smstepList = [
    {
      img: smstep1,
      children: (
        <h5>Treating Inflammation reduces cardiovascular adverse events</h5>
      )
    },
    {
      img: smstep2,
      children: (
        <h5>FAI is a novel imaging marker of coronary inflammation</h5>
      )
    },
    {
      img: smstep3,
      children: (
        <h5>FAI has prognostic value for cardiac mortality</h5>
      )
    },
    {
      img: smstep4,
      children: (
          <h5>
            Using FAI in the CaRi-Heart&reg; Risk provides a personalized
            cardiovascular risk prediction
          </h5>
      )
    }
  ];

  function goToStep(stepNumber) {
    setCurrentStep(stepNumber);
    stepContentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
    history.push(path + '?step=' + stepNumber);
  }

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 />;
      case 4:
        return <Step4 />;
      default:
        return <Step1 />;
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.get('step')) {
      goToStep(parseInt(query.get('step')));
    } else {
      setCurrentStep(1);
    }
  }, []);

  return (
    <>
      <div className='professional-background-1' />
      <div className='professionals-container container'>
        <BreadCrumb subRouter='Professionals' subHeading='Clinical Evidence' />
        <div className='row content1'>
          <div className='col-xs-12 col-md-8'>
            <p className='page-title'>Clinical Evidence</p>

            <p className='paragraph'>
              CaRi-Heart&reg; will fundamentally disrupt the approach to
              diagnosis and treatment of coronary artery disease by predicting
              heart attacks years before they happen.{' '}
            </p>
            <div className='row paragraph'>
              <div className='col-md-8'>
                <p className='paragraph'>
                  This bold claim is backed up by multiple publications in
                  top-tier scientific journals.
                </p>
              </div>
            </div>
            <img src={magnifying_glass} className='section1-image' />
          </div>
          <div className='col-xs-12 col-md-4'>
            <RightLink content={rightlinks[1]} />
          </div>
        </div>
        <div className='row content2'>
          <div className='clinical-evidence-step'>
            <StepCarousel
              activeStep={currentStep}
              list={stepList}
              onClick={(step) => goToStep(step)}
              onHover={(step) => setCurrentStepHovered(step)}
            />
          </div>
          <div className='sm-clinical-evidence-step'>
            <SmStepCarousel
              activeStep={currentStep}
              list={smstepList}
              onClick={(step) => goToStep(step)}
              onHover={(step) => setCurrentStepHovered(step)}
            />
          </div>
        </div>
        <div ref={stepContentRef}>{renderStep(currentStep)}</div>
      </div>
    </>
  );
}

const step1Charts = [
  {
    image: step1_graph1,
    link: 'https://www.nejm.org/doi/full/10.1056/NEJMoa0807646',
    text1: 'Apparently healthy persons without hyperlipidemia (low LDL) but with systemic inflammation (elevated hsCRP)',
    text2: `First occurrence of myocardial infarction, stroke, arterial
    revascularization, hospitalization for unstable angina, or
    confirmed death from cardiovascular causes`
  },
  {
    image: step1_graph2,
    link: 'https://www.nejm.org/doi/full/10.1056/NEJMoa1707914',
    text1: `Patients with previous myocardial infarction and residual
    systemic inflammation (elevated hsCRP)`,
    text2: `First occurrence of nonfatal myocardial infarction, nonfatal
    stroke, or cardiovascular death`
  },
  {
    image: step1_graph3,
    link: 'https://www.nejm.org/doi/full/10.1056/NEJMoa1912388',
    text1: `Patients with a recent myocardial infarction ({'<30'} days)
    who completed planned PCI and treated with intensive use of
    statins`,
    text2: `Composite of cardiovascular death, resuscitated cardiac
    arrest, myocardial infarction, stroke, or urgent coronary
    revascularization`
  },
  {
    image: step1_graph4,
    link: 'https://www.nejm.org/doi/full/10.1056/NEJMoa2021372',
    text1: `Stable Patients with evidence of coronary disease by ICA or
    CCTA, or Calcium score > 400`,
    text2: `Composite of cardiovascular death, myocardial infarction,
    ischemic stroke, or ischemia-driven coronary revascularization`
  }
];

const Step1 = (props) => {
  const [step1CurrentGraphHovered, setStep1CurrentGraphHovered] = useState();

  return (
    <div className='step-content step1'>
      <div className='content3'>
        <h2 className='step-title'>
          "Atherosclerosis is an inflammatory disease" (Ross NEJM 1999)
          <br />
          and inflammatory pathways predisposing coronary arteries to rupture
          and
          <br />
          thrombosis have been well described (Libby – Mechanisms of ACS – NEJM
          2013)
          <br />
          <br />
        </h2>
        <p className='paragraph'>
          Several randomized controlled trials have shown that inflammation is a
          valid therapeutic target to reduce cardiovascular events. However, the
          benefits are only modest in unselected populations, or in populations
          selected with tests indicating systemic inflammation (e.g. hsCRP)
        </p>

        <div className='row chart-content'>
          {step1Charts.map((item, index) => (
            <div
              key={index}
              onMouseEnter={() => setStep1CurrentGraphHovered(index)}
              onMouseLeave={() => setStep1CurrentGraphHovered(null)}
              className='col-xs-12 col-sm-6 col-md-3 page1-charts'>
              <img className='img' src={item.image} />
              <a href={item.link} target='_blank'>
                <div 
                  style={{
                    display: step1CurrentGraphHovered === index ? 'block' : 'none'
                  }}
                  className='hover-to-display'>
                  <div className='text1'>{item.text1}</div>
                  <div className='primary-endpoint'>PRIMARY END POINT</div>
                  <div className='text2'>{item.text2}</div>
                </div>
              </a>
            </div>
          ))}
        </div>
        <div className='chart-caption'>
          Graphs produced by Caristo based on data in the publications
        </div>
      </div>

      <div className='content4'>
        <p className='paragraph text-bold'>
          Without tests to detect coronary inflammation, it is impossible to
          target treatments to the people who will benefit most, whilst
          preventing unnecessary costs and potentially harmful side-effects in
          people who will not benefit.
        </p>
        <p className='paragraph'>
          It is likely that future clinical trials of treatments to reduce
          coronary inflammation would show much greater clinical benefits and
          cost-effectiveness if they were focused on people with high coronary
          inflammation, identified by CaRi-Heart&reg;.
        </p>
      </div>
    </div>
  );
};

const Step2 = (props) => {
  return (
    <div className='step-content step2'>
      <div className='content-header'>
        <a
          className='link'
          href='https://doi.org/10.1126/scitranslmed.aal2658'
          target='_blank'>
          <h2 className='step-title'>
            FAI is a novel imaging marker of coronary inflammation
          </h2>
          <h3 className='sub-text'>
            <i>Antonopoulos – Detecting Coronary Inflammation – STM 2017</i>
          </h3>
        </a>
      </div>
      <p className='paragraph'>
        Validated against 1400 fat biopsies, from patients undergoing cardiac
        surgery, with paired CT scans
      </p>
      <div className='video-container'>
        <video
          className='video center-block'
          src={Clinical_Evidence}
          loop
          autoPlay
          controls
        />
        <p className='paragraph'>
          Measures phenotypic changes in perivascular adipose tissue (adipocytes
          lipid content and size) in response to inflammation inside the artery
        </p>
      </div>
      <div className='page2-diagram-lg'>
        <div className='row page2-side-row'>
          <div className='col-md-3 page2-side-title-1'>
            <span>VISUALISING CORONARY INFLAMMATION</span>
          </div>
          <div className='col-md-5 page2-side-title-2'>
            <span>CORONARIES & PERIVASCULAR FAT</span>
          </div>
        </div>
        <div className='col mes'>
          <div className='col-md-5'>
            <div className='row no-margin'>
              <div className='top-left-title'>HEALTHY</div>
            </div>
            <div className='side-left-1 side-left'>
              <div className='row no-margin'>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_image1} />
                </div>
              </div>
              <div className='row no-margin'>
                <div className='col-md-9'>
                  <img className='page2-images' src={step2_graph1} />
                </div>
              </div>
            </div>
            <div className='side-left-2 side-left'>
              <div className='row no-margin'>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_image3} />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-5 margin-side-right'>
              <div className='row no-margin'>
                <div className='top-right-title'>INFLAMED</div>
              </div>
            <div className='side-right'>
              <div className='row no-margin'>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_image2} />
                </div>
                <div className='col-md-5 page2-diagram-text'>
                  PERIVASCULAR
                  <br />
                  GRADIENT OF:
                  <br />
                  <br />
                  ↑ LIPOLYSIS
                  <br />
                  <br />
                  ↓ ADIPOGENESIS
                  <br />
                  <br />
                  ↑ WATER/LIPID RATIO
                </div>
              </div>
              <div className='row no-margin'>
                <div className='col-md-9'>
                  <img className='page2-images' src={step2_graph2} />
                </div>
              </div>
              <div className='row no-margin' style={{display: 'flex'}}>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_image4} />
                </div>
                <div className='col-md-5 page2-diagram-text'>
                  CCTA WITH
                  <br />
                  PERIVASCULAR FAT
                  <br /> 
                  ATTENUATION
                  <br/>
                  INDEXING
                  <br />
                  <br />
                  <img src={step2_FAI} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row page2-side-row-end'>
          <div className='col-md-3 page2-side-title-1-border'>
            <span> </span>
          </div>
          <div className='col-md-5 page2-side-title-2-border'>
            <span> </span>
          </div>
        </div>
      </div>
      <div className='page2-diagram-sm'>
        <div className='col-sm-12 col-md-12 diagram-sm-healthy-container'>
            <div className='row no-margin'>
              <div className='col-sm-12 col-md-12 diagram-sm-header header-healthy'>HEALTHY</div>  
            </div>
            <div className='col-sm-12 col-md-12 diagram-sm-healthy'>
              <div className='row no-margin'>
                <div className='col-sm-12 col-md-12 diagram-sm-left-title-1'>CORONARIES & PERIVASCULAR FAT</div>
              </div>
              <div className='row no-margin'>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_image1} />
                </div>
              </div>
              <div className='row no-margin'>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_graph1} />
                </div>
              </div>
              <div className='row no-margin healthy-section-2'>
                <div className='col-sm-12 col-md-12 diagram-sm-left-title-2'>VISUALISING CORONARY INFLAMMATION</div>
              </div>
              <div className='row no-margin'>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_image3} />
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-12 col-md-12 diagram-sm-healthy-container'>
            <div className='row no-margin'>
              <div className='col-sm-12 col-md-12 diagram-sm-header header-inflamed'>INFLAMED</div>  
            </div>
            <div className='col-sm-12 col-md-12 diagram-sm-inflamed'>
              <div className='row no-margin'>
                <div className='col-sm-12 col-md-12 diagram-sm-left-title-1'>CORONARIES & PERIVASCULAR FAT</div>
              </div>
              <div className='row no-margin'>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_image2} />
                </div>
              </div>
              <div className='row no-margin'>
                <div className='col-md-5 page2-diagram-text sm-page2-diagram-text'>
                    PERIVASCULAR GRADIENT OF:
                    <br />↑ LIPOLYSIS
                    <br />↓ ADIPOGENESIS
                    <br />↑ WATER/LIPID RATIO
                </div>
              </div>
              <div className='row no-margin'>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_graph2} />
                </div>
              </div>
              <div className='row no-margin healthy-section-2'>
                <div className='col-sm-12 col-md-12 diagram-sm-left-title-2'>VISUALISING CORONARY INFLAMMATION</div>
              </div>
              <div className='row no-margin'>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_image4} />
                </div>
                <div className='col-md-5 page2-diagram-text sm-page2-diagram-text'>
                  CCTA WITH PERIVASCULAR FAT ATTENUATION INDEXING<br /><br />
                  <img className='sm-fai-image' src={step2_FAI} />
                </div>
              </div>
            </div>
          </div>
        {/* <div className='col-sm-12 col-md-12'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 diagram-sm-header header-inflamed'>INFLAMED</div>
            <div className='row diagram-sm-page2-side-row-inflamed'>
              <div className='col-xs-4 col-sm-4 col-md-4 diagram-sm-left-title-1'>CORONARIES & PERIVASCULAR FAT</div>
              <div className='col-xs-7 col-sm-7 col-md-7 diagram-sm-left-title-2'>VISUALISING CORONARY INFLAMMATION</div>
            </div>
            <div className='col-sm-11 col-md-11 diagram-sm-inflamed'>
              <div className='row no-margin'>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_image2} />
                </div>
                <div className='col-md-5 page2-diagram-text'>
                    PERIVASCULAR<br />GRADIENT OF:
                    <br /><br />↑ LIPOLYSIS
                    <br /><br />↓ ADIPOGENESIS
                    <br /><br />↑ WATER/LIPID RATIO
                </div>
              </div>
              <div className='row no-margin'>
                <div className='col-md-7'>
                <img className='page2-images' src={step2_graph2} />
                </div>
              </div>
              <div className='row no-margin'>
                <div className='col-md-7'>
                  <img className='page2-images' src={step2_image4} />
                </div>
                <div className='col-md-5 page2-diagram-text'>
                  CCTA WITH<br />
                  PERIVASCULAR FAT<br /> 
                  ATTENUATION<br/>
                  INDEXING<br /><br />
                  <img className='fai-image' src={step2_FAI} />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const Step3 = (props) => {
  return (
    <div className='step-content step3'>
      <h2 className='step-title'>
        Quantifying FAI from routine CCTA scans adds major new information to
        <br />
        the prediction of cardiovascular risk
      </h2>

      <div className='row'>
        <div className='col-xs-12 col-sm-12 col-md-5 page3-charts'>
          <a
            href='https://doi.org/10.1016/S0140-6736(18)31114-0'
            target='_blank'>
            <img src={step3_graph1} />
          </a>
        </div>
        <div className='col-md-2'></div>
        <div className='col-xs-12 col-sm-12 col-md-5 page3-charts'>
          <a
            href='https://doi.org/10.1016/S0140-6736(18)31114-0'
            target='_blank'>
            <img src={step3_graph2} />
          </a>
        </div>
      </div>
      <div className='row'>
        <p className='paragraph'>
          In the CRISP-CT Study, of ~4000 people who underwent a CCTA scan up to
          10 years ago and were followed up for cardiovascular events (Oikonomou
          E et al. Lancet 2018),
          <strong>
            {' '}
            abnormal FAI was associated with a 6-9x higher risk for fatal heart
            attacks and 5x higher risk for nonfatal heart attacks
          </strong>{' '}
          over the subsequent years, above-and-beyond all conventional risk
          factors (such as age, smoking, diabetes, high cholesterol).
        </p>
      </div>
      <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-xs-12 col-sm-12 col-md-5 page3-charts'>
          <a href='https://doi.org/10.1016/j.jacc.2020.05.078' target='_blank'>
            <img src={step3_graph3} />
          </a>
        </div>
        <div className='col-md-2'></div>
      </div>
      <p className='paragraph'>
        The striking predictive power of FAI was also independent of ‘high risk
        plaque features’, currently reported on CCTA scans.
      </p>
      <p className='paragraph'>
        The presence of high-risk plaque features does not predict increased
        risk in people who have a low FAI, whereas people with a high FAI have
        higher risk, even in the absence of high-risk plaque features.
      </p>
    </div>
  );
};

const Step4 = (props) => {
  return (
    <div className='step-content step4'>
      <h2 className='step-title'>
        Using FAI in clinical practice improves risk stratification and outcomes
      </h2>
      <p className='paragraph'>
        Before the clinical manifestations of atherosclerosis become apparent,
        patient management in primary prevention is based on the control of
        traditional risk factors (lipids, glucose, blood pressure) and lifestyle
        education (diet, exercise, smoking cessation).
      </p>
      <p className='paragraph'>
        In secondary prevention, the focus shifts towards symptom relief and the
        use of non-invasive testing to guide management.
      </p>
      <div className='image-section-1'>
        <a
          className='images diagram-link'
          href='https://doi.org/10.1093/eurheartj/ehz474'
          target='_blank'>
          <div className='col-xs-12 col-sm-12 col-md-4 diagram center-block'>
            <img src={step4_diagram_1} />
            <h5>
              <strong>6.5%</strong> of populaton will develop
              <br />
              obstructive plaques
            </h5>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-4 diagram'>
            <img src={step4_diagram_2} />
          </div>
          <div className='col-xs-12 col-sm-12 col-md-4 diagram'>
            <img src={step4_diagram_3} />
            <h5>
              Approx 60% of heart attacks occur in
              patients with no symptoms
            </h5>
          </div>
        </a>
        <p className='chart-caption'>
          Adapted from Antoniades - Imaging residual inflammatory cardiovascular
          risk – EHJ 2019
        </p>
      </div>
      <p className='paragraph'>
        However, over half of acute coronary syndromes occur in patients in whom
        current diagnostic testing did not reveal evidence of ischemia or a
        functionally significant coronary artery stenosis.
      </p>
      <p className='paragraph'>
        A CaRi-Heart&reg; report provides comprehensive patient risk stratification for future cardiac events,
        including:
      </p>
      <ul className='paragraph clinical-evidence-list'>
        <li>The patient’s relative risk of a fatal cardiac event, compared with people of the same age and gender, based on quantification of the coronary inflammation (FAI-Score)</li>
        <li>The absolute risk of a fatal cardiac event within the next 8 years, based on the personalised FAI-Score values, the coronary atherosclerotic plaque burden and clinical risk factors</li>
      </ul>
      <p className='paragraph'>
        The CaRi-Heart&reg; analysis significantly improves risk discrimination over clinical risk factor-based models across the full spectrum of cardiac
        risk. One way to seamlessly integrate it into patient management pathways involving CCTA is outlined below:
      </p>
      <div className='diagram1'>
        <div className='diagram-image col-sm-12 col-md-12'>
          <a className='diagram-link' href="https://doi.org/10.1093/cvr/cvab286" target='_blank'>
            <img src={step4_diagram} />
          </a>
        </div>
      </div>
      <div className='section2'>
        <p className='paragraph'>
          Compared with Calcium score, CaRi-Heart&reg; has superior prognostic performance
        </p>
        <div className='row'>
          <div className='col'></div>
          <div className='col-md-5'>
              <img className='clinical-evidence-scan' src={step4_scan_graph} />
          </div>
          <div className='col'></div>
        </div>
        <p className='paragraph'>
          and tracks dynamic changes in response to treatment
        </p>
        <a className='diagram-link' href="https://academic.oup.com/eurheartj/article/40/43/3529/5554432" target='_blank'>
        <div className='row score image-link-hover'>
            <div className='col-md-4'>
              <div className='header orange-header'>
                <h4>96h post PCI</h4>
              </div>
              <img className='clinical-evidence-scan' src={step4_scan_1} />
            </div>
            <div className='col-md-4 arrow-container'>
              <p className='chart-caption'>Optical Medical Therapy</p>
              <img className='clinical-evidence-scan' src={step4_scan_arrow} />
              <p className='paragraph text-center'>6 months</p>
            </div>
            <div className='col-md-4'>
              <div className='header'>
                <h4>6 months post PCI</h4>
              </div>
              <img className='clinical-evidence-scan' src={step4_scan_2} />
            </div>
        </div>
        </a>
        <div className='row'>
          <div className='col'></div>
          <div className='col-md-5 graph-container'>
              <img className='clinical-evidence-scan' src={step4_scan_graph_2} />
          </div>
          <div className='col'></div>
        </div>
      </div>
    </div>
  );
};
