export const rightlinks = [
  {
    name: 'PATIENTS',
    links: [
      {
        url: '/patients/what-is-cardiovascular-disease',
        title: 'What is cardiovascular disease?'
      },
      {
        url: '/patients/what-is-ccta',
        title: 'What is CCTA?'
      }
    ]
  },
  {
    name: 'PROFESSIONALS',
    links: [
      {
        url: '/professionals/clinical-evidence',
        title: 'Clinical Evidence'
      },
      {
        url: '/professionals/education',
        title: 'Education'
      },
      {
        url: '/professionals/publications',
        title: 'Publications'
      }
    ]
  },
  {
    name: 'ABOUT US',
    links: [
      {
        url: '/about/management',
        title: 'Management'
      },
      {
        url: '/about/scientific-advisory-board',
        title: 'Scientific Advisory Board'
      },
      {
        url: '/news',
        title: 'News'
      },
      {
        url: 'https://caristo.bamboohr.com/jobs/',
        title: 'Careers',
        exact: true
      }
    ]
  }
];
