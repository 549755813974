import React from 'react';
import {Switch, Route, useRouteMatch} from 'react-router-dom';
import RightLink from 'components/RightLink';
import {rightlinks} from 'contents';
import {archivePosts2020, archivePosts2019, archivePosts2018, archivePosts2017, archivePosts2016 } from 'contents/publications';
import BreadCrumb from 'components/Breadcrumb';

import Archive from 'components/publication/Archive';
import PublicationList from 'containers/Professionals/PublicationList';
import PublicationDetail from 'containers/Professionals/PublicationDetail';

export default function Publications() {
  let {path, url} = useRouteMatch();

  return (
    <>
      <div className='professional-background-1' />
      <div className='publications-container container'>
        <BreadCrumb subRouter='Professionals' subHeading='Publications' />
        <div className='row'>
          <div className='col-sm-7 col-md-8'>
            <Switch>
              <Route exact path={path}>
                <PublicationList />
              </Route>
              <Route
                path={`${path}/:slug`}
                render={(props) => (
                  <PublicationDetail slug={props.match.params.slug} />
                )}
              />
            </Switch>
          </div>
          <div className='col-sm-5 col-md-4'>
            <RightLink content={rightlinks[1]} />
            <div className='archive-container'>
              <div className='archive-title'>ARCHIVE</div>
              <div className='row'>
                <button class="btn n-year-btn" type="button" data-toggle="collapse" data-target="#collapseP2020" aria-expanded="false" aria-controls="collapseP2019">
                  2020
                </button>
                <div class="collapse" id="collapseP2020">
                  <div>
                    <Archive items={archivePosts2020} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <button class="btn n-year-btn" type="button" data-toggle="collapse" data-target="#collapseP2019" aria-expanded="false" aria-controls="collapseP2019">
                  2019
                </button>
                <div class="collapse" id="collapseP2019">
                  <div>
                    <Archive items={archivePosts2019} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <button class="btn n-year-btn" type="button" data-toggle="collapse" data-target="#collapseP2018" aria-expanded="false" aria-controls="collapseP2018">
                  2018
                </button>
                <div class="collapse" id="collapseP2018">
                  <div>
                    <Archive items={archivePosts2018} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <button class="btn n-year-btn" type="button" data-toggle="collapse" data-target="#collapseP2017" aria-expanded="false" aria-controls="collapseP2017">
                  2017
                </button>
                <div class="collapse" id="collapseP2017">
                  <div>
                    <Archive items={archivePosts2017} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <button class="btn n-year-btn" type="button" data-toggle="collapse" data-target="#collapseP2016" aria-expanded="false" aria-controls="collapseP2016">
                  2016
                </button>
                <div class="collapse" id="collapseP2016">
                  <div>
                    <Archive items={archivePosts2016} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
