import React, {useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import {latestNews, archiveNews2020, archiveNews2019, archiveNews2018} from 'contents/news';
import { Link } from 'react-router-dom';
import orange_page_btn_arrow from 'assets/images/orange_page_btn_arrow.svg';

export default function NewsDetail({slug}) {
  const [content, setContent] = useState('');
  const [notFound, setNotfound] = useState(false);
  const [newsItem, setNewsItem] = useState({});
  useEffect(() => {
    var list = latestNews.concat(archiveNews2020, archiveNews2019, archiveNews2018);
    var newsItem = list.find((i) => i.slug == slug) || {};
    setNewsItem(newsItem);
    console.log(newsItem);

    axios
      .get('/md/' + slug + '.md')
      .then((result) => {
        setContent(result.data);
      })
      .catch((error) => {
        setNotfound(true);
      });
  }, []);
  return (
    <div className='news-detail-container'>
      <h1 className='title'>{newsItem.title}</h1>
      <div className='news-content'>
        {!notFound && (
          <div>
            {/* <img src={'md/assets/' + slug.image} */}
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        )}
        {notFound && <h2>Content not found!</h2>}
      </div>
      <div className='news-go-back'>
        <Link to="/news">
          <img className='go-back-btn' src={orange_page_btn_arrow} alt='orange_page_btn_arrow'/>
          Go Back to Newsroom
        </Link>  
      </div>
    </div>
  );
}
