import React from 'react';
import BreadCrumb from 'components/Breadcrumb';
import 'assets/styles/pages/_scientificadvisoryboard.scss';

import bernard from 'assets/images/about/scientificadvisoryboard/bernard-gersh.jpg';
import ed from 'assets/images/about/scientificadvisoryboard/ed-nicol-r.jpg';
import john from 'assets/images/about/scientificadvisoryboard/john-deanfield-r.jpg';
import milind from 'assets/images/about/scientificadvisoryboard/milind-desai.jpg';
import peter from 'assets/images/about/scientificadvisoryboard/peter-libby.jpg';
import ron from 'assets/images/about/scientificadvisoryboard/ron-blankstein.jpg';
import collage from 'assets/images/about/scientificadvisoryboard/sab.png';
import Educations from 'containers/Professionals/Education';


class About extends React.Component {
  render() {
    return (
      <div className='sab-container container'>
        <BreadCrumb subRouter='About Us' subHeading='Scientific Advisory Board' />
        <div className ='container'>
          <div className='row justify-content-center'>
            <p className='page-title'>Scientific Advisory Board</p>
            <div className='row no-gutters'>
              <div className='col-md-12 text-center'>
                <img className='sab-board-images float-center' src={collage} />
              </div>
            </div>
            <p className='paragraph sab-head-paragraph text-center'>
              Our Scientific Advisory Board consists of renowned leaders in cardiovascular medicine. Their expertise and advice are invaluable in helping Caristo transform clinical outcomes through innovations like CaRi-Heart®. 
            </p>
          </div>
          <div className='row sab-profile-container no-gutters'>
            <div className='col-md-3 col-lg-3 text-center'>
              <img className='sab-profile-image' src={john} alt='Dr John Deanfield' />
            </div>
            <div className='col-md-9 col-lg-9'>
              <p className='title'>Dr. John Deanfield - Scientific Advisory Board Chair</p>
              <p className='sub-title'>University College London</p>
              <p className='paragraph bio'>
                John Deanfield (Chair) is a Professor of Cardiology at University College London. He is Director of the National Institute of Cardiovascular Outcomes Research (NICOR) in the UK and a Senior Advisor to Public Health England (PHE) on Cardiovascular Disease Prevention. He has played a leading role in the use of national data to improve outcomes from heart disease. He has championed the idea of lifetime benefits of sustained management of cardiovascular risk factors and comorbidities, introducing the concept of 'investing in your arteries'. He is Chief Medical Advisor to the new national Our Future Health programme and Chairs the National Health Check Programme Review.
                He was appointed Commander of the Order of the British Empire (CBE) in the 2021 Birthday Honours for services to the prevention of treatment of heart disease.
                Prof. Deanfied represented Britain at the Munich and Montreal Olympic games in fencing.              </p>
            </div>
          </div>
          <div className='row sab-profile-container no-gutters'>
            <div className='col-md-3 col-lg-3 text-center'>
              <img className='sab-profile-image' src={peter} alt='Dr Peter Libby' />
            </div>
            <div className='col-md-9 col-lg-9'>
              <p className='title'>Dr. Peter Libby</p>
              <p className='sub-title'>Harvard Medical School</p>
              <p className='paragraph bio'>
                Peter Libby is a cardiovascular medicine specialist at Brigham and Women’s Hospital (BWH) and the
                Mallinckrodt Professor of Medicine at Harvard Medical School (HMS). The author of over 470 original
                peer-reviewed publications, some 540 reviews, chapters, or other publications, Dr. Libby also serves
                as Editor in Chief of the leading textbook of cardiovascular medicine. Dr. Libby&#39;s research has
                received funding from the U.S. National Institutes of Health, the American Heart Association, and the
                Fondation Leducq . Dr. Libby has received research recognitions on four continents including the
                highest research awards from the American Heart Association and American College of Cardiology,
                the Gold Medal of the European Society of Cardiology, the Anitschkow award from the European
                Atherosclerosis Society, the Ernst Jung Gold Medal for Medicine, and the Earl Benditt Award for
                Vascular Biology.
              </p>
            </div>
          </div>
          <div className='row sab-profile-container no-gutters'>
            <div className='col-md-3 col-lg-3 text-center'>
              <img className='sab-profile-image' src={bernard} alt='Dr Bernard Gersh' />
            </div>
            <div className='col-md-9 col-lg-9'>
              <p className='title'>Dr. Bernard Gersh</p>
              <p className='sub-title'>Mayo Clinic</p>
              <p className='paragraph bio'>
                Bernard Gersh is Professor of Medicine at Mayo Clinic College of Medicine, Consultant in
                Cardiovascular Diseases and Internal Medicine. His past positions include The W. Proctor Harvey
                Teaching Professor of Cardiology and Chief of the Division of Cardiology at Georgetown University
                Medical Center. Dr. Gersh received his MB, ChB, from the University of Cape Town in South Africa. He
                received his Doctor of Philosophy degree from Oxford University where he was a Rhodes Scholar.
                Dr. Gersh&#39;s wide interests include the natural history and therapy of acute and chronic coronary
                artery disease, clinical electrophysiology and in particular atrial fibrillation, sudden cardiac death and
                syncope, cardiac stem cell therapy, and the epidemiology of cardiovascular disease in the developing
                world.
                He has approximately 1266 publications (1117 manuscripts and 149 book chapters). (h-index 141). In
                2014 and 2015 he was named in the Thomson Reuters list of individuals with the greatest number of
                cited scientific papers 2002-2012.
                Dr. Gersh was the 2004 recipient of the Distinguished Achievement Award of the AHA Council of
                Clinical Cardiology and the 2007 recipient of the ACC Distinguished Service Award. , the Hatter Award
                for “Advancement in the Cardiovascular Science” from University College London and the University
                of Cape Town 2009 and 2016 He received the degree of Ph.D. (honoris causa) from The University of
                Coimbra, Portugal in 2005. Dr. Gersh is the recipient of the 2012 James B. Herrick award of the AHA,
                and in 2013 he was designated Master of the American College of Cardiology. At ESC in 2013 he was
                designated as one of four “legends of modern cardiology”. Dr. Gersh is the 2015 recipient of the
                Mayo Clinic Distinguished Alumni Award, the Rene Laennec invited Lecture and Silver Medal of the
                ESC (2010), and received the Gold Medal of the ESC in August 2016. Dr. Gersh is the recipient of the
                2016 Distinguished Scientist Award of the American Heart (AHA).
              </p>
            </div>
          </div>
          <div className='row sab-profile-container no-gutters'>
            <div className='col-md-3 col-lg-3 text-center'>
              <img className='sab-profile-image' src={milind} alt='Dr Milind Desai' />
            </div>
            <div className='col-md-9 col-lg-9'>
              <p className='title'>Dr. Milind Desai</p>
              <p className='sub-title'>Cleveland Clinic</p>
              <p className='paragraph bio'>
                Milind Desai is the Director of Clinical Operations of the Robert and Suzanne Tomsich Department of Cardiovascular Medicine at the Sydell and Arnold Miller Family Heart, Vascular & Thoracic Institute at Cleveland Clinic. He is a Professor of Medicine at the Cleveland Clinic Lerner College of Medicine of Case Western Reserve University. He holds the Haslam Family Endowed Chair in Cardiovascular Medicine. He has dual appointments in the Departments of Cardiovascular Medicine (Section of Cardiovascular Imaging) and Radiology. Dr. Desai is the Director of Center for Hypertrophic Cardiomyopathy, the Medical Director of the Center for Aortic Diseases, the Medical Director for Center for Radiation Heart Disease and an integral part of the Center for Valvular heart disease. He is an expert in multimodality cardiovascular imaging, having achieved the highest level of proficiency in all imaging modalities, including cardiac MRI, cardiac CT, echocardiography and nuclear cardiology. In addition, he is the Director of Cardiovascular Imaging Research and serves on the multiple Heart, Vascular & Thoracic Institute councils, including Executive Council, Operations Council and Research Council. In addition, he serves on the Cleveland Clinic Research Compliance Committee.
              </p>
            </div>
          </div>
          <div className='row sab-profile-container no-gutters'>
            <div className='col-md-3 col-lg-3 text-center'>
              <img className='sab-profile-image' src={ron} alt='Dr Ron Blankstein' />
            </div>
            <div className='col-md-9 col-lg-9'>
              <p className='title'>Dr. Ron Blankstein</p>
              <p className='sub-title'>Harvard Medical School</p>
              <p className='paragraph bio'>
                Ron Blankstein is the Associate Director of the Cardiovascular Imaging Program, Director of Cardiac
                Computed Tomography, and a preventive cardiology specialist at Brigham and Women’s Hospital
                (BWH). In addition, he is a Professor of Medicine and Radiology at Harvard Medical School (HMS).

                Dr. Blankstein is the immediate Past-President of the Society of Cardiovascular Computed
                Tomography and serves on the Board of Directors of the American Society of Preventive Cardiology.
                Dr. Blankstein is an expert in the use of multi-modality imaging for the assessment of cardiovascular
                disease. This includes acquiring and interpreting non-invasive cardiovascular imaging tests—such as
                cardiac MRI, cardiac CT, nuclear cardiology, and exercise treadmill testing.
                The author of over 450 publications, Dr. Blankstein’s research compares the results of different
                imaging techniques as well as the impact of these tests on the patient management and outcomes.
              </p>
            </div>
          </div>
          <div className='row sab-profile-container no-gutters'>
            <div className='col-md-3 col-lg-3 text-center'>
              <img className='sab-profile-image' src={ed} alt='Dr Ed Nicol' />
            </div>
            <div className='col-md-9 col-lg-9'>
              <p className='title'>Dr. Ed Nicol</p>
              <p className='sub-title'>Royal Brompton &amp; Harefield Hospitals</p>
              <p className='paragraph bio'>
                Ed Nicol MD MBA DAvMed FRCP FRCR FACC FESC FSCCT is a consultant cardiologist who works at
                both the Royal Brompton and Chelsea &amp; Westminster Hospitals. He is an honorary senior lecturer at
                both Imperial College, London (Cardiology) and Keele University (Healthcare Leadership) where he
                gained his Doctorate and MBA respectively. He has published over 200 original papers, book chapters
                and books covering cardiovascular CT, cardiology, military and aviation medicine, and healthcare
                leadership.
                He is the current SCCT Vice-President, previous Chair of the SCCT Education committee and sits on
                the SCCT Board of Directors. He is past Chair of the UK Cardiovascular Imaging Council, is a former
                Royal College of Radiologists’ Travelling Professor of Cardiovascular Imaging, Past President of the
                British Society of Cardiovascular Imaging and British Society of Cardiovascular CT.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
