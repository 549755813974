import React from 'react';
import {Link, withRouter} from 'react-router-dom';

import caristo_logo from 'assets/images/caristo_logo.svg';
import bsi_9001 from 'assets/images/bsi_9001.png';
import bsi_13485 from 'assets/images/bsi_13485.png';
import bsi_27001 from 'assets/images/bsi_27001.png';
import ce_marking_logo from 'assets/images/ce-marking-logo.svg';


class Footer extends React.Component {
  render() {
    return (
      <div className='footer-container'>
        <div className='container footer-content'>
          <div className='row no-margin'>
              <div className='col-sm-6 col-md-3 footer-item'>
                <img src={caristo_logo} alt='caristo' />
                <p>New Barclay House</p>
                <p>234 Botley Road</p>
                <p>Oxford</p>
                <p>OX2 0HP</p>
                <br />
                <p>Registered in England and Wales</p> 
                <p>under company number 11429590</p>
              </div>
              <div className='col-sm-6 col-md-3 footer-item footer-item-links'>
                <b>Resources</b>
                <Link className="link" to='/assets/cariheart_healthcare_professional_ifu_2_3.pdf' target="_blank">
                  CaRi-Heart&reg; Healthcare Professionals Instructions for Use
                </Link>
                <Link className="link" to='/assets/caristo_website_terms_and_conditions_20210326.pdf' target="_blank">
                  Website Terms and Conditions
                </Link>
                <Link className="link" to='/assets/caristo_website_privacy_and_cookies_policy_20210326.pdf' target="_blank">
                  Privacy and Cookie Policy
                </Link>
              </div>
              <div className='col-sm-12 col-md-6'>
                <div className='footer-badges'>
                  <div className="footer-badges__image">
                    <img className="footer-ce-img" src={ce_marking_logo} alt='ce_marking' />
                    <img src={bsi_9001} alt='bsi_9001' />
                    <img src={bsi_13485} alt='bsi_13485' />
                    <img src={bsi_27001} alt='bsi_27001' />
                  </div>
                </div>
                <div className="footer-badges-description">
                    CaRi-Heart® is limited to investigational use in the United States. 
                </div>
            </div>
          </div>
        </div>
        <div className='footer-legal'>
          <p>
            All contents of this website are copyright 2018 - 2021 Caristo
            Diagnostics Ltd. All rights reserved. No content may be copied or
            reproduced without the express permission of Caristo Diagnostics
            Ltd.
          </p>
          <p>
            All copyright, trade-marks, design rights, database rights,
            patents and other intellectual property rights (registered and
            unregistered) in and on this website are owned by Caristo
            Diagnostics Ltd. and/or certain third parties.
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
