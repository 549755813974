import React, {useEffect, useRef, useState} from 'react';

export const SmStepCarousel = (props) => {
  const {list, onClick, onHover, activeStep} = props;

  const handleClick = (step) => {
    onClick && onClick(step);
  };

  const handleHover = (step) => {
    onHover && onHover(step);
  };

  return (
    <div className='sm-step-carousel col-12 row no-margin'>
      {list.map((item, index) => (
        <div
          key={index}
          onMouseEnter={() => handleHover(index + 1)}
          onClick={() => handleClick(index + 1)}
          className='col-xs-12 col-sm-12 col-md-12 select'
          style={{width: item.width}}>
          {item.children}
        </div>
      ))}
      {list.map((item, index) => (
        <img className='sm-step-main-image' key={index} src={item.img} hidden={activeStep !== index + 1} />
      ))}
    </div>
  );
};
