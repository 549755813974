import React from 'react';
import {useRouteMatch} from 'react-router-dom';

export default function Archive({items}) {
  let {path, url} = useRouteMatch();

  return (
    <div>     
      <div className='archive-posts'>
        {items.map((post, index) => (
          <div className='archive-posts__item' key={index}>
            <div className='item-date'>{post.date}</div>
            <div className='item-title'>
              <a
                href={post.slug ?  url + '/' + post.slug : post.link}
                className='link'
                target={post.slug ? '_self' : '_blank'}>
                {post.title}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
