/* eslint-disable max-len */

import React from 'react';

import {rightlinks} from 'contents';

import BreadCrumb from 'components/Breadcrumb';
import RightLink from 'components/RightLink';
import {Step} from 'components/Step';
import {Collapse} from 'components/Collapse';

import slices_1 from 'assets/images/slices_1.jpg';
import slices_left from 'assets/images/slices_left.jpg';
import slices_heart from 'assets/images/slices_heart.jpg';
import area_3_image from 'assets/images/area_3_image.jpg';
import scan_image from 'assets/images/scan_image.jpg';
// import area_1_scanner from 'assets/images/area_1_scanner.svg';
import area_1_scanner from 'assets/images/scanner.png';
import area_1_orange_swish from 'assets/images/area_1_orange_swish.svg';

class Patients extends React.Component {
  render() {
    return (
      <div className='patients-container ccta'>
        <div className='container'>
          <BreadCrumb subRouter='Patients' subHeading='What is CCTA?' />
          <div className='row'>
            <div className='col-xs-12 col-md-8'>
              <p className='page-title'>What is CCTA?</p>

              <p className='paragraph'>
                Computed tomography, more commonly known as a CT or CAT scan, is
                a diagnostic medical imaging test that produces multiple images
                of the inside of the body with x-rays. The CT scanner is a
                large, donut-shaped machine with a short tunnel in the center.
                You will be lying on a bed table that slides into it.{' '}
              </p>
            </div>
            <div className='col-xs-12 col-md-4'>
              <RightLink content={rightlinks[0]} />
            </div>
          </div>
        </div>

        <img className='hero-image' src={scan_image} />

        <div className='container'>
          <p className='paragraph'>
            Coronary CT Angiography (CCTA), also known as cardiac CT scanning,
            uses an intravenous (IV) injection of iodine-containing contrast
            material to generate high-resolution images of the coronary arteries
            and the heart.
          </p>
          <p className='paragraph'>
            Your primary care physician or cardiac specialist will determine
            whether CCTA is an appropriate test for you.
          </p>
          <div className='content3'>
            <Collapse title='How is the Procedure Performed?' id='section1'>
              <div className='row collapse-padding'>
                <div className='col-md-6'>
                  <p className='paragraph'>
                    A nurse or technologist may ask you to remove your clothes
                    above the waist and wear a hospital gown. You may be asked
                    to remove metal objects such as jewelry, which can affect
                    the CT images.
                  </p>
                  <p className='paragraph'>
                    You will be asked to lie on your back on the scanner bed
                    table. While you may lie on this table for approximately 10
                    - 30 minutes, most of this time will involve preparation.
                    The actual images only take a few seconds to obtain.
                  </p>
                  <p className='paragraph'>
                    An intravenous (IV) line will be inserted into a vein in
                    your arm. Nitroglycerin may also be given as a tablet or a
                    spray underneath your tongue, to widen the coronary arteries
                    and improve the pictures. This may cause a temporary slight
                    headache.
                  </p>
                  <p className='paragraph'>
                    The technologist or nurse will apply sticky patches called
                    electrodes on your chest.<br /> This will be used to monitor your
                    heartbeat during the exam.
                  </p>
                </div>
                <div className='col-md-6'>
                  <img className='ccta-scanner-img' src={area_1_scanner} />
                </div>
                <div className='col-md-9'>
                  <p className='paragraph'>
                    You will be asked to lie still and will receive instructions
                    to hold your breath for short<br /> periods of time (less than 15
                    seconds) while pictures are taken. It is very important to
                    be<br /> absolutely still and to not breathe, move or swallow
                    while the pictures are being taken.
                  </p>

                  <p className='paragraph'>
                    During some steps of the test, contrast dye will be injected
                    through the IV. This may cause a warm feeling all over your
                    body, which usually disappears within a minute or so.
                  </p>
                </div>
                <img className='background' src={area_1_orange_swish} />
                <div className='col-md-9'>
                  <p className='paragraph'>
                    Provided by the Society of Cardiovascular Computed Tomography
                    (SCCT). For more information{' '}
                    <a
                      className='click-here'
                      href='https://scct.org/page/For_patients'
                      target='_blank'>
                      click here
                    </a>
                    .
                  </p>
                </div>
              </div>
            </Collapse>
            <Collapse title='How Should I Prepare?' id='section2'>
              <div className='collapse-padding'>
              <p className='paragraph subtitle'>
                Based upon your condition and the doctor performing the CTA, you
                may be asked to do the following – always check with your doctor
                for your preparation before the test:
              </p>
              <ul className='list-square'>
                <li>
                  <p className='paragraph'>
                    Take a beta-blocker medication to lower your heart rate.
                    Some centers may give such medications by mouth and/or
                    inject it through a vein prior to the test. This will help
                    create the best images of the moving heart.
                  </p>
                </li>
                <li>
                  <p className='paragraph'>
                    Avoid caffeinated drinks or food (e.g. coffee, tea, sodas,
                    energy drinks, chocolate) for 12 - 24 hours prior to your
                    test.
                  </p>
                </li>

                <li>
                  <p className='paragraph'>
                    Do not eat for 4 hours or drink anything for 1 hour before
                    the test (this may vary, please be sure to check with your
                    doctor’s office prior to your test).
                  </p>
                </li>
                <li>
                  <p className='paragraph'>
                    Do not take certain medications such as Viagra, Cialis, or
                    Levitra for at least 48 - 78 hours prior to your test.
                  </p>
                </li>
                <li>
                  <p className='paragraph'>
                    Temporarily stop taking Glucophage (Metformin) for 48 hours
                    after the test.
                  </p>
                </li>
                <li>
                  <p className='paragraph'>
                    Inform your doctor of any medications you are taking –
                    including over-the-counter pain medications such as Advil or
                    Motrin.
                  </p>
                </li>
                <li>
                  <p className='paragraph'>
                    Inform your doctor and the CT technologist if you have any
                    allergies to contrast material. If you have had prior
                    reaction to injected contrast, the test may not be possible,
                    or your doctor may prescribe medications to reduce the risk
                    of such a reaction.
                  </p>
                </li>
                <li>
                  <p className='paragraph'>
                    Women should inform their doctor and the CT technologist if
                    there is any possibility that they are pregnant.
                  </p>
                </li>
                <li>
                  <p className='paragraph'>
                    If needed, blood tests will be drawn to check for kidney
                    disease prior to the test. People with mild kidney disease
                    can still receive injected contrast but may require
                    additional medications and IV fluids before or after the
                    test.
                  </p>
                </li>
                <li>
                  <p className='paragraph'>
                    If you have an irregular heartbeat it may make it difficult
                    to get good CT images so this should be clearly mentioned
                    when your appointment is made.
                  </p>
                </li>
              </ul>
              <p className='paragraph'>
                Provided by the Society of Cardiovascular Computed Tomography
                (SCCT). For more information{' '}
                <a
                  className='click-here'
                  href='https://scct.org/page/For_patients'
                  target='_blank'>
                  click here
                </a>
                .
              </p>
              </div>
            </Collapse>
            <Collapse
              title='How is the information used by my doctor?'
              id='section3'>
              <div className='collapse-padding'>
                <p className='paragraph'>
                  Your doctor will be able to look at your coronary arteries to
                  see whether there is any disease and if so, what the extent of
                  it is.
                </p>
                <p className='paragraph'>
                  The images will show everything your doctor needs to know such
                  as the degree of narrowing, amount of calcification or level of
                  plaque burden. They can also be sent to Caristo to measure
                  inflammation around the coronary arteries.
                </p>
                <p className='paragraph'>
                  With this comprehensive information, your doctor will be able to
                  select the best treatment for you.
                </p>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

export default Patients;
