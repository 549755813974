import React, {useState} from 'react';

import telephone_icon from 'assets/images/telephone_icon.svg';
import email_icon from 'assets/images/email_icon.svg';
import BreadCrumb from 'components/Breadcrumb';
import axios from 'axios';
import {Formik, Form, Field, ErrorMessage} from 'formik';

export default function Contact() {
  const [isSuccess, setIsSuccess] = useState();
  const [submitMessage, setSubmitMessage] = useState('');

  function submit(data, resetForm) {
    setSubmitMessage('');
    axios
      .post(
        'https://caristo.zendesk.com/api/v2/requests.json',
        {
          request: {
            requester: data,
            subject: 'www.caristo.com Contact Form Submission',
            comment: {
              html_body: '<p>Function: ' + data.function + '<br/>' + data.message + '</p>'
            }
          }
        },
        {
          crossDomain: true,
          headers: {'Content-Type': 'application/json'}
        }
      )
      .then((response) => {
        setSubmitMessage('Message sent!');
        setIsSuccess(true);
        resetForm({values: {function: '', message: '', name: '', email: ''}});
      })
      .catch((error) => {
        setIsSuccess(false);
        setSubmitMessage('We were unable to send your message');
      });
  }

  return (
    <div className='contact-page'>
      <div className='contact-background' />
      <div className='contact-container container'>
        <BreadCrumb subRouter='Contact' />
        <h1>Contact</h1>
        <div className='row'>
          <div className='col-sm-12 col-md-8 contact-form'>
            <div className={'submit-message' + (isSuccess ? '' : ' error')}>
              {submitMessage}
            </div>
            <Formik
              initialValues={{name: '', email: '', message: ''}}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = 'Required';
                }
                if (!values.function) {
                  errors.function = 'Required';
                }

                if (!values.message) {
                  errors.message = 'Required';
                }
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                return errors;
              }}
              onSubmit={(values, {setSubmitting, resetForm }) => {
                submit(values, resetForm);
              }}>
              {({isSubmitting, isValid, dirty}) => (
                <div>
                  <Form>
                    <Field type='text' name='name'>
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: {touched, errors},
                        meta
                      }) => (
                        <div className='contact-form-input'>
                          <label htmlFor='name'>Name</label>
                          <div className='input-wrapper'>
                            <input
                              {...field}
                              className='form-control'
                              name='name'
                              type='text'
                              placeholder='Type here...'
                            />
                            {meta.touched && meta.error && (
                              <div className='error'>{meta.error}</div>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>

                    <Field type='email' name='email'>
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: {touched, errors},
                        meta
                      }) => (
                        <div className='contact-form-input'>
                          <label htmlFor='email'>Email</label>
                          <div className='input-wrapper'>
                            <input
                              {...field}
                              className='form-control'
                              type='email'
                              name='email'
                              placeholder='Type here...'
                            />
                            {meta.touched && meta.error && (
                              <div className='error'>{meta.error}</div>
                            )}
                          </div>
                        </div>
                      )}
                    </Field>

                    <div className='contact-form-input'>
                      <label htmlFor='function'>Function</label>
                      <div style={{width: '100%', marginRight: '0px'}}>
                        <Field
                          as='select'
                          name='function'>
                          <option value=''>Select Function</option>
                          <option value='press'>Press</option>
                          <option value='cardiologist'>Cardiologist</option>
                          <option value='radiologist'>Radiologist</option>
                          <option value='other_clinician'>
                            Other Clinician
                          </option>
                          <option value='nurse_technologist'>
                            Nurse / Technologist
                          </option>
                          <option value='it'>IT / Information Security</option>
                          <option value='hospital_administration'>
                            Hospital Administration
                          </option>
                          <option value='insurer_payer'>Insurer / Payer</option>
                          <option value='industry'>Industry</option>
                          <option value='private_person'>Private Person</option>
                        </Field>
                        <ErrorMessage
                          className='error'
                          name='function'
                          component='div'
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className='form-group col-sm-12 col-md-12'>
                        <Field name='message'>
                          {({
                            field, // { name, value, onChange, onBlur }
                            form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                            meta
                          }) => (
                            <div>
                              <label
                                className='control-label'
                                htmlFor='message'>
                                Message
                              </label>
                              <div>
                                <textarea
                                  {...field}
                                  className='form-control'
                                  rows='5'
                                  name='message'
                                  placeholder="Want to know more? We'll get back to you shortly."
                                />
                                {meta.touched && meta.error && (
                                  <div className='error'>{meta.error}</div>
                                )}
                                {meta.touched && meta.error && (
                                  <div className='error'>{meta.error}</div>
                                )}
                              </div>
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <button
                      type='submit'
                      className='submit-button'
                      disabled={!isValid || !dirty}>
                      Send
                    </button>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
          <div className='col-sm-12 col-md-4'>
            <div className='contact-information'>
              <h2 className='contact-info-title'>Caristo Diagnostics</h2>
              <p>New Barclay House</p>
              <p>234 Botley Road</p>
              <p>Oxford</p>
              <p>OX2 0HP</p>

              <br />
              <a href='tel:+441865950720'>
                <img src={telephone_icon} alt='telephone' /> (+44)1865 950720
              </a>
              <a href='mailto:info@caristo.com' target='_blank'>
                <img src={email_icon} alt='email' /> info@caristo.com
              </a>
            </div>
          </div>
        </div>
      <div className='row'>
        <div className='col-md-8 contact-gdpr'>
          <span className='gdpr-title'>GDPR Statement</span> <br /><br /> 
          In completing this ‘Contact Us’ form, contacting us via our 
          <a href='mailto:info@caristo.com' target='_blank'> info@caristo.com </a> 
          email address, or calling us with an enquiry, you have consented to our contacting you in order to 
          provide you with more information on our company, as requested by you.<br /><br /> 
          In order to contact you we will process and store your name, email address, phone number 
          and the content of your message in accordance with our Privacy and Cookies Policy available below.  
          If you have any queries as to how we hold and use your personal information, if you wish to make 
          a Data Subject Access Request, or access any of your rights as a data subject under the GDPR, please 
          email us at <a href='mailto:privacy@caristo.com' target='_blank'> privacy@caristo.com</a>. If you are 
          based in the EEA, you can also reach our data protection representative within the EEA by email to 
          this address <a href='mailto:EU_Privacy@caristo.com' target='_blank'> EU_Privacy@caristo.com</a>.
          <br/><br/> 
          Click here to view our 
          <a className='click-here' href='/assets/caristo_website_privacy_and_cookies_policy_20210326.pdf' target="_blank"> Privacy and Cookie Policy</a>. 
          
        </div>
      </div>
      </div>
      <div className='contact-map'>
        <iframe
          title='map'
          width='100%'
          height='400px'
          loading='lazy'
          frameBorder='0'
          src='https://maps.google.com/maps?q=Caristo%20Diagnostics,%20Oxford&t=&z=13&ie=UTF&iwloc=&output=embed'
        />
      </div>
    </div>
  );
}
