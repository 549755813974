import React from 'react';
import { Link } from 'react-router-dom';
import 'assets/styles/pages/_patientresources.scss';
import BreadCrumb from 'components/Breadcrumb';
import HoverDisplay from 'components/HoverDisplay';


import patientresourcespdf from 'assets/pdfs/Patient_Resources.pdf'
import patientresourcescover_01 from 'assets/images/pdf_covers/cover-01.jpg'


class Patients extends React.Component {
  render() {
    return (
      <div className='patients-container ccta'>
        <div className='container'>
          <BreadCrumb subRouter='Patients' subHeading='Patient Resources' />
          <div className='row'>
            <div className='col-xs-12 col-md-12'>
              <p className='page-title'>Patient Resources</p>

              <p className='paragraph'>
                Want to learn more about how CaRi-Heart® analysis works? Take a look at our patient resources that are created to help you understand your heart condition better. Our brochures and leaflets have information on the science behind our technology, how the CaRi-Heart® analysis is conducted and what to expect from the diagnosis.
              </p>
              <p className='paragraph'>
                You can download the brochure below and share it with your referring clinician to discuss whether a CaRi-Heart® analysis would be appropriate for you, depending on your risk factors.
              </p>
              <p className='paragraph'>
                For more information, please email us on info@caristo.com
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-lg-4 pr-resources-tab'>
              <a className='images diagram-link' href={patientresourcespdf} target='_blank'>
                <HoverDisplay
                  className='item'
                  backgroundImage={patientresourcescover_01}
                  foregroundChild={
                    <div className='hover'>
                      <p className='title'>CaRi-Heart® Analysis – How We Predict Your Heart Attack Risk</p>
                      TRI-FOLD PATIENT BROCHURE
                    </div>
                  }
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Patients;
