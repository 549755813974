import React from 'react';
import { withRouter, Link, router, NavLink } from 'react-router-dom';
import {Navbar, Nav, NavDropdown } from 'react-bootstrap';


import caristo_logo from 'assets/images/caristo_logo.svg';
import twitter_icon from 'assets/images/twitter_icon.png';
import linkedin_icon from 'assets/images/linkedin_icon.png';
import instagram_icon from 'assets/images/instagram_icon.png';

class BootstrapNavbar extends React.Component{
  render(){
      return(
          <div>
            <div className='navbar-container'>
              <div className='container'>
                <div className='top-header'>
                  <div className='nav-brand'>
                    <Link to='/home'>
                      <img src={caristo_logo} alt='caristo' />
                    </Link>
                  </div>
                  <div className='col-md-3 col-lg-2 contact-header-container'>
                    <Link to='/contact' className='contact-header-btn'>
                      Contact                  
                    </Link>
                  </div>
                </div>
              </div>
            </div>
              <div className="row no-margin">
                  <div className="nb-navbar col-md-12">
                    <div className="container">
                          <Navbar collapseOnSelect expand="lg" sticky="top">
                              <Navbar.Toggle aria-controls="basic-navbar-nav" />
                              <Navbar.Collapse id="basic-navbar-nav">
                                  <Nav className="mr-auto">
                                    <Nav.Link eventkey="1" href="/home">Home</Nav.Link>
                                    <Nav.Link eventkey="2" href="/product">Product</Nav.Link>
                                    <li className="nav-item dropdown">
                                      <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdownPatients" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Patients
                                      </Link>
                                      <div className="dropdown-menu" aria-labelledby="navbarDropdownPatients">
                                        <NavDropdown.Item eventkey="4" className='nb-dropdown-item' href='/patients/paitent-resources'>Patient Resources</NavDropdown.Item>
                                        <NavDropdown.Item eventkey="5" className='nb-dropdown-item' href='/patients/what-is-cardiovascular-disease'>What Is Cardiovascular Disease?</NavDropdown.Item>
                                        <NavDropdown.Item eventkey="6" className='nb-dropdown-item' href='/patients/what-is-ccta'>What Is CCTA?</NavDropdown.Item>
                                      </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                      <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdownClinicians" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Clinicians
                                      </Link>
                                      <div className="dropdown-menu" aria-labelledby="navbarDropdownClinicians">
                                        <NavDropdown.Item eventkey="7" className='nb-dropdown-item' href='/professionals/clinical-evidence'>Clinical Evidence</NavDropdown.Item>
                                        <NavDropdown.Item eventkey="8" className='nb-dropdown-item' href='/professionals/education'>Webinars/Seminars</NavDropdown.Item>
                                        <NavDropdown.Item eventkey="9" className='nb-dropdown-item' href='/professionals/publications'>Publications</NavDropdown.Item>
                                      </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                      <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdownResources" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Resources
                                      </Link>
                                      <div className="dropdown-menu" aria-labelledby="navbarDropdownResources">
                                        <NavDropdown.Item eventkey="10" className='nb-dropdown-item' href='/resources/productsandbrochures'>Products and Brochures</NavDropdown.Item>
                                        {/* <NavDropdown.Item eventkey="11" className='nb-dropdown-item' href='/about/scientific-advisory-board'>Case Studies</NavDropdown.Item> */}
                                        <NavDropdown.Item eventkey="12" className='nb-dropdown-item' href='/news'>Newsroom</NavDropdown.Item>
                                      </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                      <Link className="nav-link dropdown-toggle" href="#" id="navbarDropdownAboutUs" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        About Us
                                      </Link>
                                      <div className="dropdown-menu" aria-labelledby="navbarDropdownAboutUs">
                                        <NavDropdown.Item eventkey="13" className='nb-dropdown-item' href='/about/management'>Meet the Team</NavDropdown.Item>
                                        <NavDropdown.Item eventkey="14" className='nb-dropdown-item' href='/about/scientific-advisory-board'>Scientific Advisory Board</NavDropdown.Item>
                                        <NavDropdown.Item eventkey="15" className='nb-dropdown-item' href='https://caristo.bamboohr.com/jobs' target='_blank'>Careers</NavDropdown.Item>
                                      </div>
                                    </li>
                                    < Nav.Link eventkey="16" className="contact-btn" href="/contact">Contact</Nav.Link>
                                    <div className='nb-social-icons'>
                                      <a eventkey="17" href="https://twitter.com/CaristoHeart" target='_blank'><img className="nb-twitter" src={twitter_icon} alt='twitter' /></a>
                                      <a eventkey="18" href="https://www.linkedin.com/company/caristo/2" target='_blank'><img className='nb-linkedin' src={linkedin_icon} alt='linkedin' /></a>
                                      <a eventkey="19" href="https://www.instagram.com/caristo_diagnostics/" target='_blank'><img className='nb-instagram' src={instagram_icon} alt='instagram' /></a>
                                    </div>
                                  </Nav>
                              </Navbar.Collapse>
                          </Navbar>
                      </div>
                  </div>
              </div>
          </div>
      )  
  }
}

export default BootstrapNavbar;