import React, {useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown'
import axios from 'axios';
import {posts} from 'contents/publications';

export default function PublicationDetail({slug}) {
  const [content, setContent] = useState('')
  useEffect(() => {
    let post = posts.find(item => item.slug == slug);
    
    axios.get(post.contentFile).then(result => {
      setContent(result.data);
    })
  }, [])
  return (
    <div className=''>
      <p className='page-title'>News</p>
      <div className='news-content'>
          <ReactMarkdown>
            {content}
          </ReactMarkdown>
      </div>
    </div>
  );
}
