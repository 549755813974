/* eslint-disable camelcase */
import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import orange_page_btn_arrow from 'assets/images/orange_page_btn_arrow.svg';

const RightLink = (props) => {
  return (
    <div className='rightlink-container'>
      <div className='rightlink-items'>
        <p className='name'> {props.content.name} </p>
        {props.content.links.map((link) => {
          const isSelected = props.location.pathname === link.url;
          return (
            <div key={link.url}>
              {!link.exact && (
                <Link
                  to={link.url}
                  className={'link' + (isSelected ? ' selected' : '')}>
                  <div className='link-title'>{link.title}</div>
                  <div className='link-next-icon'>
                    {!isSelected && (
                      <img
                        src={orange_page_btn_arrow}
                        alt='orange_page_btn_arrow'
                      />
                    )}
                  </div>
                </Link>
              )}
              {link.exact && (
                <a href={link.url} className={'link'} target='_blank'>
                  <div className='link-title'>{link.title}</div>
                  <div className='link-next-icon'>
                    {!isSelected && (
                      <img
                        src={orange_page_btn_arrow}
                        alt='orange_page_btn_arrow'
                      />
                    )}
                  </div>
                </a>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withRouter(RightLink);
