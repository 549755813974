import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import BreadCrumb from 'components/Breadcrumb';
import scanner from 'assets/images/scanner.png';
import clipboard from 'assets/images/product/clip-board.svg';
import tick from 'assets/images/product/orange-tick.svg';
import arrow from 'assets/images/product/arrow.svg';
import server from 'assets/images/product/server.png';
import computer from 'assets/images/product/computer.png';
import computersm from 'assets/images/product/computer-sm.png';


class Product extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className='container'>
          <div className='grey-arch-bckg' />
          <BreadCrumb subRouter='Product' />
          <h1 class="page-title">Cari-Heart Analysis Service</h1>
          <p className='paragraph'>
            Caristo provides state of the art cardiac risk evaluation based on the analysis of standard CCTA scans, incorporating a personalized assessment of coronary inflammation, to help clinicians make more informed patient management decisions.
          </p>
        </div>
        <div className='container'>
            <div className='col-sm-12 col-md-6'>
              <img className='product-scanner product-img' src={scanner} alt='scanner' />
            </div>
            <div className='col-sm-12 col-md-6'>
              <h2 className='step-title align-middle row'>
                <img className='tick' src={tick} alt='tick' />
                <div className="col-sm-12 col-lg-9 extra-padding">
                  Can be applied to any routine clinical CCTA
                </div>
                <img className='tick-sm' src={tick} alt='tick' />
              </h2>
              <img className='clipboard-img' src={clipboard} alt='clipboard' />
              <div className='col-sm-12 col-md-5'>
                <img className='scanner-sm' src={scanner} alt='scanner' />
              </div>
            </div>
        </div>
        <div className='container'>
          <img className='arrow' src={arrow} alt='arrow' />
        </div>
        <div className='container'>
            <div className='col-sm-12 col-md-6'>
              <h2 className='step-title align-middle row'>
                <img className='tick' src={tick} alt='tick' />
                <div className="col-sm-12 col-lg-9 extra-padding">
                  Analysis is performed using our patented, regulatory cleared platform, CaRi-Heart®
                </div>
                <img className='tick-sm' src={tick} alt='tick' />
              </h2>
            </div>
            <div className='col-sm-12 col-md-6'>
              <img className='server product-img' src={server} alt='server' />
            </div>
        </div>
        <div className='container'>
          <img className='arrow' src={arrow} alt='arrow' />
        </div>
        <div className='container'>
          <div className='col-sm-12 col-md-6'>
              <img className='computer' src={computer} alt='computer' />
              <img className='computer-sm' src={computersm} alt='computer-sm' />
          </div>
            <div className='col-sm-12 col-md-6'>
              <h2 className='step-title step-title-3 align-middle row'>
                <img className='tick' src={tick} alt='tick' />
                <div className="col-sm-12 col-lg-9 extra-padding">
                  Clinically demonstrated to improve discrimination of cardiac mortality prognosis over current risk assessment methods
                </div>
                <img className='tick-sm' src={tick} alt='tick' />
              </h2>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Product);
