import React, {useState} from 'react';

const HoverDisplay = (props) => {
  const {
    onHover,
    onLeave,
    foregroundChild,
    className = '',
    backgroundImage
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => {
    setIsHovered(true);
    onHover && onHover();
  };
  const handleLeave = () => {
    setIsHovered(false);
    onLeave && onLeave();
  };
  return (
    <div
      className={`hover-display ${className}`}
      style={{background: `url("${backgroundImage}")`, backgroundSize: 'cover'}}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}>
      <div
        className='foreground'
        style={{
          display: isHovered ? 'block' : 'none'
        }}>
        {foregroundChild}
      </div>
    </div>
  );
};
export default HoverDisplay;
