export const posts = [
  {
    slug: '',
    date: 'November 2021',
    title:
      'Biomarkers of Vascular Inflammation for Cardiovascular Risk Prognostication: A Meta-Analysis',
    description: `Alexios S.Antonopoulos MD, PhD, Andreas Angelopoulos MD, Paraskevi Papanikolaou MD, Spyridon Simantiris MD, Evangelos K. Oikonomou MD, PhD, Konstantinos Vamvakaris MD, Alkmini Koumpoura MD, Maria Farmaki MD, Marialena Trivella MD, PhD, Charalambos Vlachopoulos MD, PhD, Konstantinos Tsioufis MD, PhD, Charalambos Antoniades MD, PhD, Dimitris Tousoulis MD, PhD <br/>
                  JACC Cardiovasc Imaging - Published online 21 November 2021 `,
    contentFile: '',
    link: 'https://authors.elsevier.com/a/1e5jH,i2XrnBYB'
  },
  {
    slug: '',
    date: 'August 2021',
    title:
      'Standardised measurement of coronary inflammation using cardiovascular CT: integration in clinical care as a prognostic medical device',
    description: `Evangelos K Oikonomou, MD DPhil, Alexios S Antonopoulos, MD PhD, David Schottlander, DPhil, Mohammad Marwan, MD, Chris Mathers, MSc, Pete Tomlins, PhD, Muhammad Siddique, PhD, Laura V Klüner, MD MSc, Cheerag Shirodaria, MD MSc, Michail C Mavrogiannis, MD, Sheena Thomas, Agostina Fava, MD, John Deanfield, MD PhD, Keith M Channon, MD, Stefan Neubauer, MD, Milind Y Desai, MD, Stephan Achenbach, MD, Charalambos Antoniades, MD PhD
    </br > Cardiovascular Research – Published online 27 August 2021`,
    contentFile: '',
    link: 'https://doi.org/10.1093/cvr/cvab286'
  },
  {
    slug: '',
    date: 'March 2021',
    title:
      'Assessing Cardiovascular Risk by Using the Fat Attenuation Index in Coronary CT Angiography',
    description: `Laura V. Klüner, Evangelos K. Oikonomou, Charalambos Antoniades <br/>Radiology: Cardiothoracic Imaging, Volume 3 Issue 1:e200563`,
    contentFile: '',
    link: 'https://doi.org/10.1148/ryct.2021200563'
  },
  {
    slug: '',
    date: 'February 2021',
    title:
      'The year in cardiovascular medicine 2020: digital health and innovation',
    description:
      'Charalambos Antoniades, Folkert W Asselbergs, Panos Vardas <br/>European Heart Journal, Volume 42, Issue 7, 14 February 2021, Pages 732–9',
    contentFile: '',
    link: 'https://doi.org/10.1093/eurheartj/ehaa1065'
  }
];

export const archivePosts2020 = [
  {
    slug: '',
    date: 'August 2020',
    title:
      'Perivascular Fat Attenuation Index Stratifies Cardiac Risk Associated With High-Risk Plaques in the CRISP-CT Study',
    description: `Evangelos K. Oikonomou, Milind Y. Desai, Mohamed Marwan, Christos P. Kotanidis, Alexios S.Antonopoulos, David Schottlander, Keith M. Channon, Stefan Neubauer, Stephan Achenbach, Charalambos Antoniades. <br/>Journal of the American College of Cardiology, Volume 76, Issue 6, 11 August 2020, Pages 755-75`,
    contentFile: '',
    link: 'https://doi.org/10.1016/j.jacc.2020.05.078'
  },
  {
    slug: '',
    date: 'February 2020',
    title:
      'Artificial intelligence in medical imaging: A radiomic guide to precision phenotyping of cardiovascular disease',
    description:
      'Evangelos K Oikonomou, Musib Siddique, Charalambos Antoniades<br/>Cardiovascular Research, Volume 116, Issue 13, 1 November 2020, Pages 2040–2054',
    contentFile: '',
    link: 'https://doi.org/10.1093/cvr/cvaa021'
  }
];

export const archivePosts2019 = [
  {
    slug: '',
    date: 'October 2019',
    title:
      'Detecting Coronary Inflammation With Perivascular Fat Attenuation Imaging Making Sense From Perivascular Attenuation Maps',
    description:
      'Charalambos Antoniades, Cheerag Shirodaria <br/>JACC Cardiovascular Imaging Volume 12, Issue 10, October 2019, Pages 2011-4',
    contentFile: '',
    link: 'https://doi.org/10.1016/j.jcmg.2018.12.024'
  },
  {
    slug: '',
    date: 'September 2019',
    title:
      'Cardiac Computed Tomography Assessment of Coronary Inflammation and Other Plaque Features',
    description:
      'Evangelos K. Oikonomou, Henry W. West, Charalambos Antoniades <br/>Arteriosclerosis, Thrombosis, and Vascular Biology. 2019;39:2207–19',
    contentFile: '',
    link: 'https://doi.org/10.1161/ATVBAHA.119.312899'
  },
  {
    slug: '',
    date: '',
    title:
      'A novel machine learning-derived radiotranscriptomic signature of perivascular fat improves cardiac risk prediction using coronary CT angiography',
    description:
      'Evangelos K Oikonomou, Michelle C Williams, Christos P Kotanidis, Milind Y Desai, Mohamed Marwan, Alexios S Antonopoulos, Katharine E Thomas, Sheena Thomas, Ioannis Akoumianakis, Lampson M Fan, Sujatha Kesavan, Laura Herdman, Alaa Alashi, Erika Hutt Centeno, Maria Lyasheva, Brian P Griffin, Scott D Flamm, Cheerag Shirodaria, Nikant Sabharwal, Andrew Kelion, Marc R Dweck, Edwin J R Van Beek, John Deanfield, Jemma C Hopewell, Stefan Neubauer, Keith M Channon, Stephan Achenbach, David E Newby, Charalambos Antoniades <br/>European Heart Journal, Volume 40, Issue 43, 14 November 2019, Pages 3529–43',
    link: 'https://academic.oup.com/eurheartj/article/40/43/3529/5554432'
  },
  {
    slug: '',
    date: 'July 2019',
    title: 'Imaging residual inflammatory cardiovascular risk',
    description:
      'Charalambos Antoniades, Alexios S. Antonopoulos, John Deanfield <br/>European Heart Journal, Volume 41, Issue 6, 7 February 2020, Pages 748–58',
    contentFile: '',
    link: 'https://doi.org/10.1093/eurheartj/ehz474'
  },
  {
    slug: '',
    date: '',
    title:
      'Association of Biologic Therapy With Coronary Inflammation in Patients With Psoriasis as Assessed by Perivascular Fat Attenuation Index',
    description:
      'Youssef A. Elnabawi, MD; Evangelos K. Oikonomou, MD; Amit K. Dey, MD; Jennifer Mancio,MD, PhD; Justin A. Rodante, PA-C; Milena Aksentijevich, MSc; Harry Choi, MSE; Andrew Keel, RN; Julie Erb-Alvarez, MPH; Heather L. Teague, PhD; Aditya A. Joshi, MD; Martin P. Playford, PhD; Benjamin Lockshin, MD; Andrew D. Choi, MD; Joel M. Gelfand, MD, MSCE; Marcus Y. Chen, MD; David A. Bluemke, MD, PhD; Cheerag Shirodaria, MD; Charalambos Antoniades, MD, PhD; <br/>Nehal N. Mehta, MD, MSCE<br/>JAMA Cardiology. 2019;4(9):885-91',
    contentFile: '',
    link: 'https://jamanetwork.com/journals/jamacardiology/fullarticle/2740281'
  },
  {
    date: 'March 2019',
    title:
      'State-of-the-art review article. Atherosclerosis affecting fat: What can we learn by imaging perivascular adipose tissue?',
    description:
      'Charalambos Antoniades, Christos P. Kotanidis, Daniel S. Berman<br/>JCCT, VOLUME 13, ISSUE 5, P288-96',
    link:
      'https://www.journalofcardiovascularct.com/article/S1934-5925(18)30618-X/fulltext'
  }
];

export const archivePosts2018 = [
  {
    date: 'October 2018',
    title: 'The role of adipose tissue in cardiovascular health and disease.',
    description:
      'Evangelos K. Oikonomou & Charalambos Antoniades<br/>Nature Reviews Cardiology volume 16, pages83–99',
    link: 'https://doi.org/10.1038/s41569-018-0097-6'
  },
  {
    date: 'Sept 2018',
    title:
      'Non-invasive detection of coronary inflammation using computed tomography and prediction of residual cardiovascular risk (the CRISP CT study): a post-hoc analysis of prospective outcome data.',
    description:
      'Oikonomou EK, Marwan M, Desai MY, Mancio J, Alashi A, Hutt Centeno E, Thomas S, Herdman L, Kotanidis CP, Thomas KE, Griffin BP, Flamm SD, Antonopoulos AS, Shirodaria C, Sabharwal N, Deanfield J, Neubauer S, Hopewell JC, Channon KM, Achenbach S, Antoniades C. <br>Lancet. 2018</br> Sep 15;392(10151):929-939.',
    link: 'https://doi.org/10.1016/S0140-6736(18)31114-0'
  },
  {
    date: 'June 2018',
    title:
      'Perivascular Fat Attenuation Index by Computed Tomography as a Metric of Coronary Inflammation.',
    description: '',
    link: 'https://doi.org/10.1016/j.jacc.2018.03.511'
  },
  {
    date: 'April 2018',
    title: 'The Role of Epicardial Fat in Pericardial Diseases.',
    description: '',
    link: 'https://doi.org/10.1007/s11886-018-0986-7'
  }
];

export const archivePosts2017 = [
  {
    date: 'June 2017',
    title: 'Detecting human coronary inflammation by imaging perivascular fat.',
    description: '',
    link: 'https://doi.org/10.1126/scitranslmed.aal2658'
  },
  {
    date: 'Feb 2017',
    title:
      'Prognostic implications of epicardial fat volume quantification in acute pericarditis.',
    description: '',
    link: 'https://doi.org/10.1111/eci.12711'
  }
];

export const archivePosts2016 = [
  {
    date: 'Oct 2016',
    title:
      'From the BMI paradox to the obesity paradox: the obesity-mortality association in coronary heart disease.',
    description: '',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/27405510'
  },
  {
    date: 'Mar 2016',
    title:
      'Mutual Regulation of Epicardial Adipose Tissue and Myocardial Redox State by PPAR-γ/Adiponectin Signalling.',
    description: '',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/26838789'
  },
  {
    date: 'Jul 2016',
    title:
      'Ectopic and Visceral Fat Deposition in Lean and Obese Patients With Type 2 Diabetes.',
    description: '',
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/27364051'
  }
];


