/* eslint-disable max-len */

import React, {useState, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {rightlinks} from 'contents';

import BreadCrumb from 'components/Breadcrumb';
import RightLink from 'components/RightLink';
import {Step} from 'components/Step';
import {Card} from 'components/Card';
import {Collapse} from 'components/Collapse';

import slices_1 from 'assets/images/slices_1.jpg';
import slices_2 from 'assets/images/slices_2.jpg';
import sm_slices_1 from 'assets/images/sm_slices_1.jpg';
import sm_slices_2 from 'assets/images/sm_slices_2.jpg';
import slices_left from 'assets/images/slices_left.jpg';
import slices_heart_sm from 'assets/images/slices_heart_sm.png';
import slices_heart from 'assets/images/slices_heart.jpg';
import area_3_image from 'assets/images/area_3_image.jpg';
import area_1_image from 'assets/images/area_1_image.jpg';
import orange_swish from 'assets/images/orange_swish.svg';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function Patients() {
  const [showSlice1, setShowSlice1] = useState(true);
  const [expandedSection, setExpandedSection] = useState('');
  let {search} = useLocation();

  useInterval(() => {
    // Your custom logic here
    setShowSlice1(!showSlice1);
  }, 4000);

  useEffect(() => {
    const query = new URLSearchParams(search);
    setExpandedSection(query.get('expanded'));
  }, []);

  return (
    <div className='patients-container disease'>
      <div className='container'>
        <BreadCrumb
          subRouter='Patients'
          subHeading='What is Cardiovascular Disease?'
        />
        <div className='row'>
          <div className='col-xs-12 col-md-8'>
            <h1 className='page-title'>
              What is cardiovascular <br /> disease?
            </h1>
            <p className='paragraph'>
              Cardiovascular diseases are the leading worldwide cause of death.
            </p>
            <p className='paragraph'>
              The commonest form is Coronary Artery Disease (CAD) affecting the
              arteries on the surface of the heart, which supply blood to the
              heart muscle.
            </p>
            <p className='paragraph'>
              Through an inflammatory process, called atherosclerosis,
              cholesterol is deposited in the wall of these arteries, which
              causes build-up of localised narrowings called “plaques”. This
              progressively reduces blood flow, especially during exertion,
              which can result in chest pain (called angina).
            </p>
            <p className='paragraph'>
                Inflammation in the arterial wall can weaken the surface of a
                plaque, leading to its sudden rupture and resulting in a blood
                clot which may block the artery and cause a heart attack or even
                sudden death, even when blood flow was not significantly reduced
                before.
              </p>
          </div>
          <div className='col-xs-12 col-md-4'>
            <RightLink content={rightlinks[0]} />
          </div>
        </div>
        <div className='content2'>
          <div className='row no-margin'>
            <div className='col-md-1 no-padding'>
              <img className='left-blue' src={slices_left} alt='slices_left' />
            </div>
            <div className='col-md-6 slice-container no-padding'>
              <div className='image-section row'>
                <div className='col-md-11'>
                  <div className={'slices col-xs-11 col-sm-12 col-md-11 ' + (showSlice1 ? 'fadein' : 'fadeout')}>
                    <img className='image2' src={slices_1} alt='slices_1' />
                    <div className='col-xs-8 col-sm-8 no-padding'>
                      <img className='sm-image2' src={sm_slices_1} alt='sm_slices_1' />
                    </div>
                    <div className='sm-slices-list col-xs-4 col-sm-4'>
                      <Step list={[
                          'Normal',
                          'Silent Inflammation',
                          'Structural Changes',
                          'Plaque Rupture',
                          'Heart Attack'
                        ]}
                      />
                    </div>
                    <div className='slices-list'>
                      <Step list={[
                          'Normal',
                          'Silent Inflammation',
                          'Structural Changes',
                          'Plaque Rupture',
                          'Heart Attack'
                        ]}
                      />
                    </div>
                  </div>
                  <div className={'slices col-xs-11 col-sm-12 col-md-11 ' + (!showSlice1 ? 'fadein' : 'fadeout')}>
                    <img className='image2' src={slices_2} alt='slices_2' />
                    <div className='col-xs-8 col-sm-8 no-padding'>
                      <img className='sm-image2' src={sm_slices_2} alt='sm_slices_2' />
                    </div>
                    <div className='sm-slices-list col-xs-4 col-sm-4'>
                      <Step list={[
                          'Normal',
                          'Silent Inflammation',
                          'Structural Changes',
                          'Stable disease'
                        ]}
                      />
                    </div>
                    <div className='slices-list'>
                      <Step list={[
                          'Normal',
                          'Silent Inflammation',
                          'Structural Changes',
                          'Stable disease'
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-5 no-padding'>
              <img className='heart-img' src={slices_heart} alt='slices_heart' />
            </div>
            <div className='col-sm-12 no-padding'>
              <img className='heart-sm' src={slices_heart_sm} alt='slices_heart_sm' />
            </div>
          </div>
        </div>
        <div className='col-xs-12 col-md-8'>
              <p className='paragraph'>
                Over time, CAD can weaken the heart muscle. This may lead to
                heart failure, a serious condition where the heart can’t pump as
                much blood as the body needs.
              </p>
              <p className='paragraph'>
                The same arterial disease often occurs in other important
                arteries in the body, such as those supplying the brain, and can
                lead to a stroke.
              </p>
            </div>
      </div>
      <div className='content3' id='collapse'>
        <div className='container'>
          <Collapse
            id='section1'
            isOpen={expandedSection == 'section1'}
            title='What are the Risk Factors for Coronary Artery Disease?'>
              <div className='collapse-padding'>
            <div className='row'>
              <div className='col-md-3'>
                <Card content='High blood pressure' />
                <Card content='High cholesterol' />
                <Card content='Overweight and obesity' />
                <Card content='Diabetes' />
                <Card content='A family history of heart disease' />
              </div>
              <div className='col-md-9'>
                <img className='patient-img' src={area_1_image} />
              </div>
            </div>
            <br />
            <br />
            <div className='row'>
              <p className='paragraph'>
                The major risk factors for CAD are known and include high
                cholesterol levels, high blood pressure, smoking, diabetes and
                family history. Alcohol, stress, diet, obesity and lack of fitness
                are also important.
              </p>
              <p className='paragraph'>
                Managing these risk factors with a healthy lifestyle (such as
                reduced alcohol intake, smoking cessation and regular physical
                activity), and where necessary medications, can help reduce your
                future risk of developing CAD and its complications.
              </p>
            </div>
            </div>
          </Collapse>
        </div>
        <div className='collapse2'>
          <div className='container'>
            <Collapse
              id='section2'
              isOpen={expandedSection == 'section2'}
              title='What are the Symptoms of Coronary Artery Disease?'>
                <div className='collapse-padding'>
              <div className='parag'>
                <p className='paragraph'>
                  The most common symptom of CAD is angina, which causes tightness in the chest
                  often described as squeezing.
                </p>
                <p className='paragraph'>
                  Symptoms of a heart attack include chest pain, which is often
                  severe but may also feel like indigestion or heartburn, upper
                  back or neck pain, cold sweat, dizziness or nausea, vomiting,
                  extreme fatigue and shortness of breath.
                </p>

                <p className='paragraph'>
                  If you have symptoms, or important risk factors, your doctor
                  may wish to determine the extent and pattern of your coronary
                  artery disease, to provide best advice on your care.
                </p>
              </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className='container'>
          <Collapse
            id='section3'
            isOpen={expandedSection == 'section3'}
            title='The Solution from Caristo'>
              <div className='collapse-padding'>
            <p className='paragraph'>
              Inflammation cannot be detected by current tests, which aim at
              identifying the degree of narrowing of coronary arteries, and
              their impact on blood flow. Unfortunately, close to 2/3 of all
              heart attacks occur in arteries that were not significantly
              narrowed before a plaque rupture caused the event.
            </p>
            <div className='row'>
              <div className='col-md-5'>
                <p className='paragraph'>
                  Caristo has developed a new technology that analyses images of a
                  routine test called Coronary Computed Tomography Angiography
                  (CCTA) to quantify the extent of inflammation in the coronary
                  arteries. Large scientific studies in thousands of patients
                  have shown that Caristo’s technology can detect inflammation
                  before narrowings have developed in the coronary arteries.
                </p>
                <p className='paragraph'>
                  When incorporating this information into the CaRi-Heart&reg;
                  Risk, close to 1/3 of patients have their future risk of
                  having a heart attack or dying from a heart-related
                  condition reevaluated: In 15% of the cases to a lower risk, in
                  16% of the cases to a HIGHER risk.
                </p>
                <p className='paragraph'>
                  This means your doctor will be able to better define the most
                  appropriate treatment for you to prevent future heart attacks
                  or avoid unnecessary medication and associated side effects.
                </p>
              </div>
              <div className='col-md-6'>
                <img className='patient-img' src={area_3_image} />
              </div>
            </div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
}
