import React from 'react';

class About extends React.Component {
  render() {
    return (
      <div className='container about-container'>
        <p>Careers</p>
      </div>
    );
  }
}

export default About;
