import BreadCrumb from 'components/Breadcrumb';
import RightLink from 'components/RightLink';
import HoverDisplay from 'components/HoverDisplay';
import {rightlinks} from 'contents';
import React from 'react';

import cheerag_shirodaria from 'assets/images/about/management/cheerag_shirodaria.png';
import charalambos_antoniades from 'assets/images/about/management/charalambos_antoniades.png';
import stefan_neubauer from 'assets/images/about/management/stefan_neubauer.png';
import keith_channon from 'assets/images/about/management/keith_channon.png';
import Dan_Green_ID from 'assets/images/about/management/Dan_Green_ID.jpg';
import david_schottlander from 'assets/images/about/management/david_schottlander.png';
import Tamsin_Crane_ID from 'assets/images/about/management/Tamsin_Crane_ID.jpg';
import Serge_Bellon_ID from 'assets/images/about/management/Serge_Bellon_ID.JPG';
import James_Davies_ID from 'assets/images/about/management/James_Davies_ID.jpg';

class About extends React.Component {
  render() {
    return (
      <div className='about-container container company-history'>
        <BreadCrumb subRouter='About Us' subHeading='Management' />
        <div className='row'>
          <div className='col-md-8'>
            <p className='page-title'>Company History</p>
            <p className='title'>Caristo Diagnostics</p>
            <p className='sub-title'>Founded in 2018</p>
            <p className='paragraph bio'>
              Caristo was founded in 2018 by Cardiologists at the University of
              Oxford, following a decade of scientific research and technical
              development, to revolutionise diagnosis and treatment. With Care
              as one of our core values, we are committed to our mission of
              transforming health outcomes through clinical and scientific
              innovation.
            </p>
            <p className='paragraph caption'>
              Our first “game-changing” technology provides unique insights into
              coronary artery inflammation.
            </p>
          </div>
          <div className='col-md-4'>
            <RightLink content={rightlinks[2]} />
          </div>
        </div>
        <div className='row content1'>
          <div className='col-md-12'>
            <p className='paragraph'>
              While we have known for over 50 years that heart attacks are
              caused by inflammation in coronary arteries (which supply blood to
              the heart), physicians have been unable to visualize and measure
              coronary inflammation with routine cardiac tests.
            </p>

            <p className='paragraph'>
              It is now possible to extract this information from any clinical
              CT heart scan with Caristo’s technology (known as CaRi-Heart®),
              allowing physicians to prescribe effective, personalised
              treatments with our more comprehensive risk assessment tool.
            </p>
            <p className='paragraph'>
              CaRi-Heart® received CE mark, under the new MDR process, in
              February 2021 and is commercially available in Europe.
            </p>
            <p className='paragraph'>
              New products in the pipeline will also be capable of predicting
              stroke and diabetes risk, years in advance.
            </p>
          </div>
        </div>
        <p className='title'>The Team at Caristo</p>

        <div className='team'>
          <div className='col-md-6 col-lg-4 management-pictures'>
            <HoverDisplay
              className='item'
              backgroundImage={cheerag_shirodaria}
              foregroundChild={
                <div className='hover'>
                  <p className='sub-title'>
                    Co-founder and Chief Executive Officer
                  </p>
                  <p className='title'>Dr. Cheerag Shirodaria</p>
                  <p className='sub-title'>MBBS MD MBA FRCP</p>
                  <p className='paragraph'>
                    Has been involved in cardiovascular research for more than
                    25 years. Was General Manager for Medical Devices &
                    Diagnostics and Cardiovascular Therapeutic Area Head at
                    Covance. Is an Honorary Consultant Cardiologist at Oxford
                    University Hospitals NHS Foundation Trust.
                  </p>
                </div>
              }
            />
          </div>
          <div className='col-md-6 col-lg-4 management-pictures'>
            <HoverDisplay
              className='item'
              backgroundImage={charalambos_antoniades}
              foregroundChild={
                <div className='hover'>
                  {' '}
                  <p className='sub-title'>
                    Co-founder and Chief Scientific Officer
                  </p>
                  <p className='title'>Prof. Charalambos Antoniades</p>
                  <p className='sub-title'>MD PhD FESC</p>
                  <p className='paragraph'>
                    Pioneered the research on the relationship between adipose
                    tissue and the cardiovascular system, with specific interest
                    in non-invasive imaging of inflammation. This knowledge has
                    been applied in a variety of diseases, ranging from
                    cardiovascular disease to COVID-19. Deputy Head of the
                    Division of Cardiovascular Medicine at the University of
                    Oxford, and Chair of the British Atherosclerosis Society.
                  </p>
                </div>
              }
            />{' '}
          </div>
          <div className='col-md-6 col-lg-4 management-pictures'>
            <HoverDisplay
              className='item'
              backgroundImage={stefan_neubauer}
              foregroundChild={
                <div className='hover'>
                  <p className='sub-title'>
                    Co-founder and Chief Translational Officer
                  </p>
                  <p className='title'>Prof. Stefan Neubauer</p>
                  <p className='sub-title'>MD FRCP FACC FMedSci FSCMR</p>
                  <p className='paragraph'>
                    Devoted his work to the development and application of novel imaging 
                    techniques in MRI and spectroscopy, and most recently in coronary CT. 
                    Head of the Division of Cardiovascular Medicine and Director of the 
                    Oxford Centre for Clinical Magnetic Resonance Research (OCMR) at the 
                    University of Oxford and Past President of the Society for Cardiovascular 
                    Magnetic Resonance (SCMR). 
                  </p>
                </div>
              }
            />
          </div>
          <div className='col-md-6 col-lg-4 management-pictures'>
            <HoverDisplay
              className='item'
              backgroundImage={keith_channon}
              foregroundChild={
                <div className='hover'>
                  <p className='sub-title'>
                    Co-founder and Chief Medical Officer
                  </p>
                  <p className='title'>Prof. Keith Channon</p>
                  <p className='sub-title'></p>
                  <p className='paragraph'>
                    Clinical interventional cardiologist and highly experienced
                    research leader in cardiometabolic disease and risk.
                    Professor at the University of Oxford, with expertise in
                    healthcare innovations in the NHS through NIHR and Director
                    of Academic Health Science Centre.
                  </p>
                </div>
              }
            />
          </div>
          <div className='col-md-6 col-lg-4 management-pictures'>
            <HoverDisplay
              className='item'
              backgroundImage={Dan_Green_ID}
              foregroundChild={
                <div className='hover'>
                  <p className='sub-title'>Chief Operating Officer</p>
                  <p className='title'>Dr Dan Green</p>
                  <p className='sub-title'>MSci PhD</p>
                  <p className='paragraph'>
                    Over 20 years' experience in medical imaging and devices,
                    both software and hardware. Responsible for the day-to-day
                    operation of the company, including finance and the
                    management and delivery of Caristo's analysis services.
                  </p>
                </div>
              }
            />{' '}
          </div>
          <div className='col-md-6 col-lg-4 management-pictures'>
            <HoverDisplay
              className='item'
              backgroundImage={david_schottlander}
              foregroundChild={
                <div className='hover'>
                  <p className='sub-title'>Chief Technical Officer</p>
                  <p className='title'>Dr David Schottlander</p>
                  <p className='sub-title'>PhD</p>
                  <p className='paragraph'>
                    Over 20 years' product and technology leadership in software
                    and hardware medical devices in companies from start-up to
                    multi-national. Has led commercialisation of multiple novel
                    clinical applications and healthcare platforms (e.g. PET
                    Myocardial Bloodflow, teamplay digital ecosystem), as well
                    as leading device redesign to drive company growth and
                    scale-up.
                  </p>
                </div>
              }
            />{' '}
          </div>
          <div className='col-md-6 col-lg-4 management-pictures'>
            <HoverDisplay
              className='item'
              backgroundImage={Tamsin_Crane_ID}
              foregroundChild={
                <div className='hover'>
                  <p className='sub-title'>General Counsel</p>
                  <p className='title'>Tamsin Crane</p>
                  <p className='sub-title'>PhD</p>
                  <p className='paragraph'>
                    Corporate and commercial solicitor with extensive experience
                    advising emerging and established companies on commercial
                    contracts, protection of intellectual property rights,
                    regulatory and corporate governance matters, strategic
                    relationships and financings
                  </p>
                </div>
              }
            />{' '}
          </div>
          <div className='col-md-6 col-lg-4 management-pictures'>
            <HoverDisplay
              className='item'
              backgroundImage={Serge_Bellon_ID}
              foregroundChild={
                <div className='hover'>
                  <p className='sub-title'>Chief Commercial Officer</p>
                  <p className='title'>Serge Bellon</p>
                  <p className='sub-title'></p>
                  <p className='paragraph'>
                    Over 30 years of experience in medical imaging and devices,
                    mostly in the cardiovascular field, including assignments in
                    Japan and the USA. Held marketing, sales and general
                    management positions in start-up and multinational
                    companies.
                  </p>
                </div>
              }
            />{' '}
          </div>
          <div className='col-md-6 col-lg-4 management-pictures'>
            <HoverDisplay
              className='item'
              backgroundImage={James_Davies_ID}
              foregroundChild={
                <div className='hover'>
                  <p className='sub-title'>Head of Quality and Regulatory</p>
                  <p className='title'>James Davis</p>
                  <p className='sub-title'></p>
                  <p className='paragraph'>
                    Over 20 years experience working with medical devices at
                    large multinational companies as well as startup and SMEs.
                    Responsible for regulatory compliance and quality
                    management, has set up a combined management system
                    compliant to ISO 9001, 13485 and 27001 requirements.
                  </p>
                </div>
              }
            />{' '}
          </div>
        </div>
      </div>
    );
  }
}

export default About;
