import React from 'react';
import {posts} from 'contents/publications';

const PostList = function ({posts}) {
  return (
    <div className='post-list'>
      {posts.map((post, index) => (
        <div className='post-item' key={index}>
          <div className='post-item__date'>{post.date}</div>
          <a href={post.link} target='_blank' className='link post-item__title'>
            {post.title}
          </a>
          <div
            className='post-item__description'
            dangerouslySetInnerHTML={{__html: post.description}}></div>
        </div>
      ))}
    </div>
  );
};

export default function PublicationList() {
  return (
    <div>
      <p className='page-title'>Publications</p>
      <PostList posts={posts}></PostList>
    </div>
  );
}
