import React from 'react';
import {Switch, Route, useRouteMatch} from 'react-router-dom';
import {archiveNews2020, archiveNews2019, archiveNews2018} from 'contents/news';
import NewsList from './NewsList';
import BreadCrumb from 'components/Breadcrumb';
import NewsDetail from './NewsDetail';
import RightLink from 'components/RightLink';
import {rightlinks} from 'contents';
import Archive from 'components/publication/Archive';

export default function LatestNews() {
  let {path, url} = useRouteMatch();
  return (
    <div className='publications-container container'>
      <BreadCrumb subRouter='About Us' subHeading='News'/>
      <div className='row'>
        <div className='col-sm-7 col-md-8'>
          <Switch>
            <Route exact path={path}>
              <NewsList />
            </Route>
            <Route
              exact
              path={`${path}/:slug`}
              render={(props) => <NewsDetail slug={props.match.params.slug} />}
            />
          </Switch>
        </div>
        <div className='col-sm-5 col-md-4'>
          <RightLink content={rightlinks[2]} />
          <div className='archive-container'>
            <div className='archive-title'>ARCHIVE</div>
              <div className='row'>
                <button class="btn n-year-btn" type="button" data-toggle="collapse" data-target="#collapse2020" aria-expanded="false" aria-controls="collapse2020">
                  2020
                </button>
                <div class="collapse" id="collapse2020">
                  <div>
                    <Archive items={archiveNews2020} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <button class="btn n-year-btn" type="button" data-toggle="collapse" data-target="#collapse2019" aria-expanded="false" aria-controls="collapse2019">
                  2019
                </button>
                <div class="collapse" id="collapse2019">
                  <div>
                    <Archive items={archiveNews2019} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <button class="btn n-year-btn" type="button" data-toggle="collapse" data-target="#collapse2018" aria-expanded="false" aria-controls="collapse2018">
                  2018
                </button>
                <div class="collapse" id="collapse2018">
                  <div>
                    <Archive items={archiveNews2018} />
                  </div>
                </div>
              </div>
          </div>      
        </div>
      </div>
    </div>
  );
}
