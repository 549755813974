export const latestNews = [
  {
    slug: '',
    date: 'October 2021',
    title:
      'CT Coronary Perivascular Fat Assessment Can Stratify Cardiac Risk Associated With High-Risk Plaques',
    description:
      'This article in Diagnostic And Interventional Cardiology (DAIC) provides a nice summary of the data presented at last ESC.',
    contentFile: '',
    link:
      'https://www.dicardiology.com/article/ct-coronary-perivascular-fat-assessment-can-stratify-cardiac-risk-associated-high-risk'
  },
  {
    slug: '',
    date: 'September 2021',
    title:
      'Artificial intelligence for heart attack prediction',
    description:
      'Artificial intelligence (AI) can help identify people at high risk of a fatal heart attack years before it strikes – thanks to new research that we have funded.',
    contentFile: '',
    link:
      'https://www.bhf.org.uk/what-we-do/our-research/research-successes/artificial-intelligence-and-heart-attack'
  },
  {
    slug: '',
    date: 'August 2021',
    title:
      'Two New AI assessments Offered in Welsh hospital',
    description:
      'St Joseph’s Hospital, in partnership with Heart & Lung Imaging (H&L), has become the first hospital in Wales to offer two artificial intelligence (AI)-enabled imaging assessments, which can identify a range of respiratory and cardiac conditions, potentially in their earliest stages. ',
    contentFile: '',
    link:
      'https://www.nhsx.nhs.uk/ai-lab/ai-lab-programmes/ai-health-and-care-award/ai-health-and-care-award-winners/'
  },
  {
    slug: '',
    date: 'June 2021',
    title:
      'Caristo recipient of an NHS AI Award Phase 3 project',
    description:
      'The AI Award is making £140 million available over four years to accelerate the most promising AI technologies for health and social care. There have been two rounds of competition since its launch in September 2020.',
    contentFile: '',
    link:
      'https://www.nhsx.nhs.uk/ai-lab/ai-lab-programmes/ai-health-and-care-award/ai-health-and-care-award-winners/'
  },
  {
    slug: '',
    date: 'June 2021',
    title:
      '£36 million boost for AI technologies to revolutionise NHS care',
    description:
      'Thousands of patients and NHS staff will benefit from dozens of new pioneering projects awarded a share of £36 million to test state-of-the-art AI technology. The projects will help the NHS to transform the quality of care and the speed of diagnoses for conditions such as lung cancer.',
    contentFile: '',
    link:
      'https://www.nhsx.nhs.uk/news/36-million-boost-for-ai-technologies-to-revolutionise-nhs-care/'
  },
  {
    slug: '',
    date: 'June 2021',
    title:
      'Artificial intelligence that predicts heart attacks in use in hospitals',
    description:
      'CaRi-Heart takes a normal CT scan, analyses it using AI technology and develops a more precise picture of conditions in a heart and arteries. The Sunday Telegraph',
    contentFile: '',
    link:
      'https://www.msn.com/en-gb/health/medical/artificial-intelligence-that-predicts-heart-attacks-in-use-in-hospitals/ar-AAKJKCz'
  },
  {
    slug: '',
    date: 'June 2021',
    title:
      'Artificial intelligence for heart attack prediction',
    description:
      '"Artificial intelligence (AI) can help identify people at high risk of a fatal heart attack years before it strikes – thanks to new research that we have funded." The British Heart Foundation highlights one of its research successes.',
    contentFile: '',
    link:
      'https://www.bhf.org.uk/what-we-do/our-research/research-successes/artificial-intelligence-and-heart-attack'
  },
  {
    slug: '',
    date: 'March 2021',
    title:
      'Risk screening in cardiovascular disease: the role of CT coronary angiography in asymptomatic patients, why wait?',
    description:
      'In this editorial of the British Cardiovascular Society, Dr. John Graby gives an excellent overview on the role of CCTA in assessing cardiovascular risk, highlighting the important role of FAI.',
    contentFile: '',
    link:
      'https://www.britishcardiovascularsociety.org/resources/editorials/articles/risk-screening-cardiovascular-disease-role-ct-coronary-angiography-asymptomatic-patients'
  },
  {
    slug: '',
    date: 'March 2021',
    title:
      'Meet the founders',
    description:
      'Cheerag Shirodaria and Charalambos Antoniades were recently interviewed by OSI (Oxford Sciences Innovation), one of the early investors of Caristo Diagnostics.',
    contentFile: '',
    link:
      'https://www.oxfordsciencesinnovation.com/news/meet-the-founders-cheerag-shirodaria-ceo-and-charalambos-antoniades-cso-caristo-diagnostics/'
  },
  {
    slug: '',
    date: 'March 2021',
    title:
      'Coverage of the BHF Press release on CaRi-Heart® receiving CE Mark',
    description:
      'The announcement from the British Heart Foundation was featured as an exclusive article in the 28th of March edition of the Sunday Times.',
    contentFile: '',
    link:
      'https://www.thetimes.co.uk/article/ai-spots-heart-risk-hidden-on-scans-thl5f9p8p'
  },
  {
    slug: '',
    date: 'March 2021',
    title:
      'Caristo Diagnostics announces EU approval for AI technology which can predict heart attacks years in advance',
    description:
      'Caristo Diagnostics, an Oxford-based health-tech company, is delighted to announce that its first product, CaRi-Heart®, has received a CE mark under the new European Medical Device Regulations (MDR). CaRi-Heart® will fundamentally disrupt the approach to diagnosis and treatment of coronary artery disease, by predicting heart attacks years before they happen with the measurement of coronary inflammation from a routine coronary CT scan. Caristo’s mission is to transform health outcomes through clinical and scientific innovation, using scientific breakthroughs made by Cardiologists at the University of Oxford...',
    contentFile: '',
    link:
      'https://www.bhf.org.uk/what-we-do/news-from-the-bhf/news-archive/2021/march/bhf-researchers-secure-eu-approval-for-new-ai-heart-attack-prediction-technology'
  },
  {
    slug: '',
    date: 'Feb 2021',
    title:
      'Índice de atenuação de gordura (FAI): uma mudança de paradigma na cardiologia',
    description:
      'Dr. Fabiano M. Serfaty interviews Dr. Cheerag Shirodaria, physician associated with the discovery of the Fat Attenuation Index (FAI). Medscape Brazil (article in Portuguese).',
    contentFile: '',
    link:
      'https://portugues.medscape.com/verartigo/6505946?src=WNL_ptmdpls_210215_mscpedit_gen&uac=224414BT&impID=3188764&faf=1'
  },
];

export const archiveNews2020 = [
  {
    slug: '',
    date: 'September 2020',
    title:
      'Further Recommendations for the use of Coronary CTA in First-Line Investigations Of Suspected Coronary Artery Disease',
    description:
      'A new article in the Journal of the American College of Cardiology adds further support to the growing shift towards using CT based angiography as a first-line investigation into patients presenting with stable chest pain and suspected coronary artery disease.',
    contentFile: '',
    link: 'https://doi.org/10.1016/j.jacc.2020.06.078'
  },
  {
    slug: '',
    date: '',
    title: 'Caristo Among First Recipients Of NHSX AI Award',
    description:
      'Caristo is delighted to announce that it has been successful in its application for NHSX/NIHR funding to accelerate development and NHS adoption of its AI-based diagnostics technologies. The project, ACRE-CT (Artificial Intelligence to improve Cardiometabolic Risk Evaluation using CT) will involve the collection of many thousands of existing CT scans, along with follow-up information on the patients, for training state-of-the-art machine learning algorithms. The results will be a new software product, CaRi-M (M for Metabolic), which will apply the latest machine learning techniques to routine chest and abdominal CT scans, already performed in the NHS, to assess the extent and future risk of cardiometabolic disorders (diabetes and prediabetes). Caristo is one of 44 applicants to receive a grant in the first round of the AI competition; with over 500 entries it is a testament to our team and technology that we have been successful.',
    contentFile: '',
    link:
      'https://www.nhsx.nhs.uk/news/nhs-ai-lab-speed-cancer-and-heart-care/'
  },
  {
    slug: '',
    date: 'July 2020',
    title:
      'Low-attenuation Coronary Plaque Burden May Become Next Big Cardiac Risk Assessment',
    description:
      'A feature article in DIAC (Diagnostic and Interventional Cardiology) provides an overview of Caristo’s new technology, discussed at the annual SCCT meeting',
    contentFile: '',
    link:
      'https://www.dicardiology.com/article/low-attenuation-coronary-plaque-burden-may-become-next-big-cardiac-risk-assessment'
  },
  {
    slug: 'professor-antoniades-speaks-at-scct-2020',
    date: '',
    title:
      'Professor Antoniades speaks at the virtual Society of Cardiac Computed Tomography',
    description: '',
    link: ''
  },
  {
    slug: 'caristo-selected-for-nature-spin-off-prize-grand-final',
    date: '',
    title: 'Caristo selected for Nature Spin-Off Prize Grand Final',
    description: '',
    link:
      'https://www.caristo.com/news/caristo-selected-for-nature-spin-off-prize-grand-final'
  },
  {
    slug: 'caristo-graduates-from-the-inagural-creative-destruction-lab',
    date: 'June 2020',
    title:
      'Caristo graduates from the inaugural Creative Destruction Lab start-up accelerator in Oxford',
    description: '',
    link:
      'https://www.caristo.com/news/caristo-graduates-from-the-inagural-creative-destruction-lab'
  },
  {
    slug: 'caristo-achieves-fda-clearance-of-cari-cloud',
    title: 'Caristo achieves FDA clearance of CaRi-Cloud',
    description: '',
    link:
      'https://www.caristo.com/news/caristo-achieves-fda-clearance-of-cari-cloud'
  },
  {
    slug:
      'caristo-supported-study-orfan-gains-national-covid-19-flagship-status',
    title:
      'Caristo supported study ORFAN gains national COVID 19 Flagship Programme status from the British Heart foundation',
    description: '',
    link: ''
  }
];

export const archiveNews2019 = [
  {
    slug: 'prof-antoniades-presents-new-ai-powered',
    date: 'September 2019',
    title:
      'Prof Antoniades presents new AI-powered Imaging biomarker at European Society of Cardiology',
    description: '',
    contentFile: '',
    link: ''
  },
  {
    slug: 'press-coverage-of-prof-antoniades-new-ai-based',
    title:
      'Press Coverage of Prof. Antoniades new AI-based CVD risk prediction',
    description: '',
    contentFile: '',
    link: ''
  },
  {
    slug: 'oxford-researchers-show-fai-is-superior-to-other',
    title:
      'Oxford researchers show FAI is superior to other CT measurements in CVD risk prediction',
    description: '',
    contentFile: '',
    link: ''
  },
  {
    slug: 'the-crisp-ct-study',
    date: 'August 2019',
    title: 'The CRISP-CT study',
    description: '',
    contentFile: '',
    link: ''
  },
  {
    slug: 'new-way-of-reading-scans-can-predict',
    date: 'July 2019',
    title:
      'New ways of reading scans can predict heart attack risk, research find',
    description: '',
    contentFile: '',
    link: ''
  },
  {
    slug: 'peri-coronary-adipose-tissue-density-is-associated',
    date: 'March 2019',
    title:
      'Peri-coronary adipose tissue density is associated with 18F-Sodium Fluoride coronary uptake in stable patients with high-risk plaques',
    description: '',
    link: ''
  },
  {
    slug: 'treatment-with-biologic-therapy-in-psoriasis-is-associated',
    title:
      'Treatment with biology therapy in psoriasis is associated with reduction in coronary artery inflammation',
    description: '',
    contentFile: '',
    link: ''
  },
  {
    slug: 'winner-of-2019-eurominnies',
    date: 'February 2019',
    title: 'Winner of 2019 Eurominnies',
    description: '',
    link: ''
  },
  {
    slug: 'noninvasive-detection-of-perivascular-inflammation',
    date: 'January 2019',
    title:
      'Noninvasive detection of perivascular inflammation by Coronary Computed Tomography in the CRISP-CT study and its implications for residual cardiovascular risk',
    description: '',
    link: ''
  }
];

export const archiveNews2018 = [
  {
    slug: 'noninvasive-coronary-imaging-with-cardiac-computed',
    date: 'December 2018',
    title:
      'Noninvasive coronary imaging with Cardiac Computed Tomography and Cardiovascular Magnetic Resonance',
    description: '',
    link: ''
  },
  {
    slug: 'oxford-secures-17-5-million-to-lead',
    date: 'November 2018',
    title:
      'Oxford secured £17.5 Million to lead national AI Healthcare programmes',
    description: '',
    link: ''
  },
  {
    slug: 'vulnerable-plaque-are-targeted-therapies',
    date: 'October 2018',
    title: 'Vulnerable plaque: are targeted therapies within reach?',
    description: '',
    link: ''
  },
  {
    slug: 'researchers-test-new-techniques-to-rule',
    title:
      'Researchers test new techniques to rule out and predict heart attacks',
    description: '',
    contentFile: '',
    link: ''
  }
];
