import React from 'react';
import {Route, BrowserRouter, Switch, Redirect} from 'react-router-dom';

import Navbar from 'components/Header';
import Footer from 'components/Footer';

import Home from 'containers/Home';
import Contact from 'containers/Contact';
import Product from 'containers/Product';

import PatientResources from 'containers/Patients/PatientResources';
import PatientsWhatIsCCTA from 'containers/Patients/WhatIsCCTA';
import PatientsWhatIsCardiovascularDisease from 'containers/Patients/WhatIsCardiovascularDisease';

import ProfessionalsClinicalEvidence from 'containers/Professionals/ClinicalEvidence';
import ProfessionalsEducation from 'containers/Professionals/Education';
import ProfessionalsPublications from 'containers/Professionals/Publications';

import Management from 'containers/About/Management';
import AboutScientificAdvisoryBoard from 'containers/About/ScientificAdvisoryBoard';
import AboutCareers from 'containers/About/Careers';

import ProductsAndBrochures from 'containers/About/ProductsAndBrochures';
import News from 'containers/About/News';
import ScrollToTop from 'components/ScrollToTop';

class App extends React.Component {
  componentDidMount() {
    if (
      !window.location.host.startsWith('localhost') &&
      window.location.host !== "d29kxuu177qfjl.cloudfront.net" &&
      window.location.host !== 'www.caristo.com'
    ) {
      window.location.href =
        'https://www.caristo.com' + window.location.pathname;
    }
  }
  render() {
    return (
      <div className='app-container'>
          <BrowserRouter>
          <Navbar />
          <div className='base-container'>
            <div className="page-content-container">
              <ScrollToTop>
                <Switch>
                <Route path='/' exact render={(props) => <Home />} />
                <Route path='/contact' exact render={(props) => <Contact />} />
                <Route path='/product' exact render={(props) => <Product />} />

                <Redirect path='/patients' exact redirect to='/patients/what-is-cardiovascular-disease'/>
                <Route path='/patients/paitent-resources' exact render={(props) => <PatientResources />} />
                <Route path='/patients/what-is-cardiovascular-disease' exact render={(props) => <PatientsWhatIsCardiovascularDisease />} />
                <Route path='/patients/what-is-ccta' exact render={(props) => <PatientsWhatIsCCTA />} />

                

                <Redirect exact path='/professionals' redirect to='/professionals/clinical-evidence'/>
                <Route path='/professionals/clinical-evidence' render={(props) => <ProfessionalsClinicalEvidence />} />
                <Route exact path='/professionals/education' render={(props) => <ProfessionalsEducation />} />
                <Route path='/professionals/publications' render={(props) => <ProfessionalsPublications />} />

                <Redirect exact path='/about' redirect to='/about/management' />
                <Route path='/about/management' exact render={(props) => <Management />} />
                <Route path='/about/scientific-advisory-board' exact render={(props) => <AboutScientificAdvisoryBoard />} />
                <Route path='/news' render={(props) => <News />} />
                <Route path='/about/careers' exact render={(props) => <AboutCareers />} />
                <Route path='/resources/productsandbrochures' render={(props) => <ProductsAndBrochures />} />
                
                <Redirect path='*' redirect to='/' />
              </Switch>
              </ScrollToTop>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
