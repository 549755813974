import React from 'react';
import {Link} from 'react-router-dom';

const BreadCrumb = (props) => (
  <div className='breadcrumb-container'>
    <Link className='link breadcrumb-text' to='/'>
      Home
    </Link>
    <span className='spliter'>|</span>
    <span className='breadcrumb-text'>{props.subRouter}</span>
    {props.subHeading && (
      <>
        <span className='spliter'>|</span>
        <span className='breadcrumb-text'>{props.subHeading}</span>
      </>
    )}
  </div>
);

export default BreadCrumb;